import { RoleModel } from "./role.model";

export class ProfileModel {
    public constructor(init?: Partial<ProfileModel>) {
      Object.assign(this, init);
    }
  
    public _id!: string
    public name!: string
    public description!: string
    public checked!: boolean;
    public roles!:  RoleModel[]
  }
  
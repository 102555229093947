import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Toastr } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { PurchaseCategoryBaseComponent } from '../purchase-category-base.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PurchaseCategoryService } from 'src/app/services/purchase-category.service';
import { PurchaseCategoryModel } from 'src/app/shared/models/purchase/purchase-category.model';

@Component({
  selector: 'app-purchase-category-view',
  templateUrl: './purchase-category-view.component.html',
  styleUrls: ['./purchase-category-view.component.scss']
})
export class PurchaseCategoryViewComponent extends PurchaseCategoryBaseComponent implements OnInit {

  state: any;
  selectedItems: PurchaseCategoryModel[] = [];

  constructor(
    protected override purchaseCategoryService: PurchaseCategoryService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override router: Router,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService
  ) {
    super(purchaseCategoryService, toastr, modalService, cacheService, confirmationModal, router, deviceService);

    this.state = this.router.getCurrentNavigation()?.extras?.state;
  }

  get form() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    const data = (this.state?.['data'] as any);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.checkRoute(data._id ? this.module.permission.UPDATE : this.module.permission.ADD);

    this.createForm(data);
  }

  onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.purchaseCategoryService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.purchaseCategoryService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }
}

import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Toastr } from "src/app/services/toastr.service";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { BaseComponent } from "../../base/base.component";
import { CacheService } from "src/app/services/cache.service";
import { PurchaseCategoryService } from "src/app/services/purchase-category.service";
import { PurchaseCategoryModel } from "src/app/shared/models/purchase/purchase-category.model";
import { DeviceDetectorService } from "ngx-device-detector";
import { Module } from "src/app/shared/models/common/module.interface";

@Component({
    template: ''
})
export abstract class PurchaseCategoryBaseComponent extends BaseComponent {
    public formGroup!: FormGroup;
    public data: PurchaseCategoryModel[] = [];
    public auxData: PurchaseCategoryModel[] = [];
    public disableConfirmButton = true;
    public itemOpenedToEdit: any;

    public module: Module = {
        name: 'Categoria',
        pluralName: 'Categorias',
        parentName: 'Compras',
        urlView: '/purchase/category/view',
        urlList: '/purchase/category/list',
        permission: {
            GET: 'PURCHASE_CATEGORY_GET',
            ADD: 'PURCHASE_CATEGORY_ADD',
            UPDATE: 'PURCHASE_CATEGORY_UPDATE',
            DELETE: 'PURCHASE_CATEGORY_DELETE'
        }
    }

    constructor(
        protected purchaseCategoryService: PurchaseCategoryService,
        protected toastr: Toastr,
        protected modalService: ModalService,
        protected cacheService: CacheService,
        protected confirmationModal: ConfirmationModalService,
        protected override router: Router,
        protected override deviceService: DeviceDetectorService) {
        super(deviceService, router);
    }

    public createForm(purchaseCategory: PurchaseCategoryModel = new PurchaseCategoryModel()) {
        this.itemOpenedToEdit = purchaseCategory;
        this.formGroup = new FormGroup({
            _id: new FormControl(purchaseCategory._id),
            name: new FormControl(purchaseCategory.name, Validators.required)
        });
    }

    public afterSave(action: string): void {
        this.toastr.success(`${this.module.name} ${action} com sucesso!`);
        this.router.navigate([this.module.urlList]);
    }

    public formGroupToModel(): PurchaseCategoryModel {

        this.changeTextToUppercase(this.formGroup, ['name']);

        return new PurchaseCategoryModel({
            _id: this.formGroup.get('_id')?.value,
            name: this.formGroup.get('name')?.value
        })
    }

    // public clearForm(): void {
    //     this.formGroup.reset();
    // }

    public async remove(): Promise<void> {
        this.confirmationModal.open();
    }
}
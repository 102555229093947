import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CompanyService } from 'src/app/services/company.service';
import { Toastr } from 'src/app/services/toastr.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { MyProfileModel } from 'src/app/shared/models/admin/my-profile.model';
import { LocalStorageHelper } from 'src/app/shared/utils/local-storage';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  formMyProfile!: FormGroup;
  formChangePassword!: FormGroup;
  myProfile!: MyProfileModel;

  constructor(
    private userInfoService: UserInfoService,
    private toastr: Toastr,
    private modalService: ModalService,
    private companyService: CompanyService,
    private deviceService: DeviceDetectorService) {
    this.createForm(new MyProfileModel())
  }

  ngOnInit(): void {
    this.myProfile = LocalStorageHelper.getItem<MyProfileModel>('USER_INFO');

    const companyNames: any[] = [];

    this.companyService.find()
      .then((companies) => {
        this.myProfile.company.forEach((r: { toString: () => string; }, i: any) => {
          companyNames.push(companies.find((cs) => cs._id.toString() === r.toString()));
        });

        this.myProfile.company = companyNames.map(x => x.name).join(', ');
        this.createForm(this.myProfile);
      });
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  createForm(myProfile: MyProfileModel): void {
    this.formMyProfile = new FormGroup({
      _id: new FormControl(myProfile._id),
      full_name: new FormControl(myProfile.full_name, Validators.required),
      email: new FormControl(myProfile.email, Validators.required),
      phone_number: new FormControl(myProfile.phone_number, Validators.required),
      company: new FormControl({ value: myProfile.company, disabled: true }, Validators.required),
      profile: new FormControl({ value: myProfile.profile?.name, disabled: true }, Validators.required),
    });

    this.formChangePassword = new FormGroup({
      password: new FormControl('', [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl('', [Validators.required, this.confirmPasswordValidator()])
    });
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const isValid = value?.length >= 8 && hasUpperCase && hasNumber;

      if (!isValid) {
        return { passwordRequirements: true };
      }

      return null;
    };
  }

  get passwordControl() {
    return this.formChangePassword?.get('password');
  }

  get confirmPasswordControl() {
    return this.formChangePassword?.get('confirmPassword');
  }

  confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      const isValid = value === this.passwordControl?.value;

      if (!isValid) {
        return { passwordRequirements: true };
      }

      return null;
    };
  }

  changePassword(): void {
    this.userInfoService.changePassword(this.passwordControl?.value)
      .then(() => {
        this.toastr.success('Senha alterada com sucesso');
        this.modalService.close();
      }, () => {
        this.modalService.close();
        this.toastr.error('Não foi possivel alterar a senha, tente novamente ou contate o administrador');
      })
  }

  openChangePasswordModal(): void {
    this.modalService.open('modal-my-profile');
  }

  clearForm(): void {
    this.formChangePassword.reset();
  }
}

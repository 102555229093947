import { ItemsModel } from "./items.model";

export class PurchaseCategoryItemsModel {
  public constructor(init?: Partial<PurchaseCategoryItemsModel>) {
    Object.assign(this, init);
  }

  public _id!: string;
  public category!: any;
  public category_name!: string;
  public items!: ItemsModel[];
  public checked!: boolean;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { PurchaseCategoryListComponent } from './purchase-category-list/purchase-category-list.component';
import { PurchaseCategoryRoutingModule } from './purchase-category-routing.module';
import { PurchaseCategoryViewComponent } from './purchase-category-view/purchase-category-view.component';
import { PurchaseCategoryService } from 'src/app/services/purchase-category.service';
import { BackgroundRunningService } from 'src/app/services/background-running.service';
import { CurrentStockQuantityService } from 'src/app/services/current-stock-quantity.service';


@NgModule({
  declarations: [PurchaseCategoryListComponent, PurchaseCategoryViewComponent],
  imports: [
    CommonModule,
    PurchaseCategoryRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [PurchaseCategoryService, BackgroundRunningService, CurrentStockQuantityService],
  exports: []
})
export class PurchaseCategoryModule { }

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h3>{{module.pluralName}}</h3>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{module.parentName}}</a></li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8">
                    <div class="input-group input-group-sm" style="border: 0.5px solid #ced4da;">
                        <input type="text" class="form-control" [(ngModel)]="search" (keyup)="filter()"
                            style="border: none;">
                        <span class="input-group-append">
                            <button *ngIf="!search" type="button" class="btn  btn-flat"><i
                                    class="fas fa-search fa-fw"></i></button>
                            <button *ngIf="!!search" (click)="clearSearch()" type="button" class="btn  btn-flat"><i
                                    class="fas fa-times"></i></button>

                        </span>
                    </div>
                </div>
                <div class="col-4">
                    <button
                        *ngIf="hasPermission(module.permission.DELETE) && selectedItems && selectedItems.length && !isMobile()"
                        type="button" class="btn btn-danger btn-sm btn-card-action" title="Excluir" (click)="remove()">
                        <i class="fas fa-trash-alt fa-sm"></i> excluir
                    </button>
                    <button *ngIf="hasPermission(module.permission.ADD)" type="button"
                        class="btn btn-success btn-sm btn-card-action" (click)="addNew()" title="Novo">
                        <i class="fas fa-plus fa-sm"></i> novo
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body table-responsive p-0">
            <table class="table table-hover text-nowrap">
                <thead>
                    <tr *ngIf="!isMobile()">
                        <th style="width: 5px;">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" [(ngModel)]="selectAllChecked"
                                    (click)="selectAll($event)" type="checkbox" id="checkboxSelectAll" />
                                <label for="checkboxSelectAll" class="custom-control-label"></label>
                            </div>
                        </th>
                        <th>Nome</th>
                        <th>Perfil</th>
                        <th>Empresa</th>
                        <th style="width: 50px;">Status</th>
                        <th style="width: 5px;"></th>
                    </tr>
                    <tr *ngIf="isMobile()">
                        <th>Nome</th>
                        <th style="width: 20px;"></th>
                    </tr>
                </thead>
                <tbody *ngIf="data.length > 0 && !isMobile()">
                    <tr *ngFor="let item of data; let i = index">
                        <td>
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" [checked]="item.checked"
                                    (click)="selectItem($event, i)" type="checkbox" id="checkbox{{i}}" />
                                <label for="checkbox{{i}}" class="custom-control-label"></label>
                            </div>
                        </td>
                        <td>
                            {{item.full_name}}
                        </td>
                        <td>
                            {{item.profile.name}}
                        </td>
                        <td title="{{item.companies_names}}">
                            {{item.companies_names}}
                        </td>
                        <td>
                            <span *ngIf="item.enabled" class="badge bg-success">Ativo</span>
                            <span *ngIf="!item.enabled" class="badge bg-warning">Inativo</span>
                        </td>
                        <td>
                            <button type="button" class="btn btn-tool" title="abrir" (click)="openView(item)">
                                <i class="fas fa-external-link-alt"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngIf="data.length > 0 && isMobile()">
                    <tr *ngFor="let item of data; let i = index" (click)="selectedItem(item)">
                        <td (click)="openView(item)">
                            {{item.full_name}}
                        </td>
                        <td>
                            <div class="dropdown">
                                <a *ngIf="hasPermission(module.permission.UPDATE) || hasPermission(module.permission.DELETE)"
                                    class="btn btn-table" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a *ngIf="hasPermission(module.permission.UPDATE)" class="dropdown-item"
                                        (click)="openView(item)"><i class="fas fa-external-link-alt"></i> abrir</a>
                                    <a *ngIf="hasPermission(module.permission.DELETE)" class="dropdown-item"
                                        (click)="remove()"><i class="fas fa-trash-alt fa-sm"></i>
                                        excluir</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngIf="data.length == 0">
                    <tr>
                        <td colspan=" isMobile() ? 2 : 6">Nenhum registro encontrado</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?">
</confirmation-modal>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoggedComponent } from './logged.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { UserService } from 'src/app/services/user.service';


@NgModule({
  declarations: [
    LoggedComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [RouterModule],
  providers: [UserService]
})
export class LoggedModule { }

import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Toastr } from 'src/app/services/toastr.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { UserBaseComponent } from '../user-base.component';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent extends UserBaseComponent implements OnInit {

  state!: any;

  constructor(
    protected override deviceService: DeviceDetectorService,
    protected override router: Router,
    protected override toastr: Toastr,
    protected override confirmationModal: ConfirmationModalService,
    private userService: UserService) {

    super(toastr, router, deviceService, confirmationModal);

    this.state = this.router.getCurrentNavigation()?.extras?.state;
  }
  ngOnInit(): void {
    const data = (this.state?.['data'] as any);
    this.profiles = (this.state?.['profiles'] as any);
    this.companies = (this.state?.['companies'] as any);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.createForm(data);
  }

  profileSelected($event: any): void {
    this.form['profile'].setValue($event.target.value || null);
    this.form['profile'].markAsDirty();
  }

  onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.userService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.userService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }
}

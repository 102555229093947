import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Toastr } from "src/app/services/toastr.service";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { BaseComponent } from "../../base/base.component";
import { CacheService } from "src/app/services/cache.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { PurchaseItemsService } from "src/app/services/purchase-items.service";
import { PurchaseItemsModel } from "src/app/shared/models/purchase/purchase-items.model";
import { Module } from "src/app/shared/models/common/module.interface";

@Component({
    template: ''
})
export abstract class PurchaseItemsBaseComponent extends BaseComponent {
    public formGroup!: FormGroup;
    public data: PurchaseItemsModel[] = [];
    public auxData: PurchaseItemsModel[] = [];
    public disableConfirmButton = true;
    public itemOpenedToEdit: any;

    public module: Module = {
        name: 'Item',
        pluralName: 'Itens',
        parentName: 'Compras',
        urlView: '/purchase/items/view',
        urlList: '/purchase/items/list',
        permission: {
            GET: 'PURCHASE_ITEMS_GET',
            ADD: 'PURCHASE_ITEMS_ADD',
            UPDATE: 'PURCHASE_ITEMS_UPDATE',
            DELETE: 'PURCHASE_ITEMS_DELETE'
        }
    }

    public unitTypes = [
        'BALDE',
        'BARRA',
        'CAIXA',
        'FARDO',
        'GALÃO',
        'GRAMAS',
        'KILO',
        'LITRO',
        'MAÇOS',
        'PACOTE',
        'PEÇAS',
        'PEDAÇOS',
        'UNIDADE',
        'DÚZIA',
    ];

    constructor(
        protected purchaseItemsService: PurchaseItemsService,
        protected toastr: Toastr,
        protected modalService: ModalService,
        protected cacheService: CacheService,
        protected confirmationModal: ConfirmationModalService,
        protected override router: Router,
        protected override deviceService: DeviceDetectorService) {
        super(deviceService, router);
    }

    get form() {
        return this.formGroup.controls;
    }

    public createForm(purchaseItems: PurchaseItemsModel = new PurchaseItemsModel()) {
        this.itemOpenedToEdit = purchaseItems;
        this.formGroup = new FormGroup({
            _id: new FormControl(purchaseItems._id),
            name: new FormControl(purchaseItems.name, Validators.required),
            unitType: new FormControl(purchaseItems.unitType, Validators.required)
        });
    }

    public afterSave(action: string): void {
        this.toastr.success(`${this.module.name} ${action} com sucesso!`);
        this.router.navigate([this.module.urlList])
    }

    public formGroupToModel(): PurchaseItemsModel {

        this.changeTextToUppercase(this.formGroup, ['name']);

        return new PurchaseItemsModel({
            _id: this.formGroup.get('_id')?.value,
            name: this.formGroup.get('name')?.value,
            unitType: this.formGroup.get('unitType')?.value,
        });
    }

    public remove(): void {
        this.confirmationModal.open();
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { PurchaseCategoryItemsListComponent } from './purchase-category-items-list/purchase-category-items-list.component';
import { PurchaseCategoryItemsRoutingModule } from './purchase-category-items-routing.module';
import { PurchaseCategoryItemsViewComponent } from './purchase-category-items-view/purchase-category-items-view.component';
import { PurchaseCategoryItemsService } from 'src/app/services/purchase-category-items.service';


@NgModule({
  declarations: [PurchaseCategoryItemsListComponent, PurchaseCategoryItemsViewComponent],
  imports: [
    CommonModule,
    PurchaseCategoryItemsRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [PurchaseCategoryItemsService],
  exports: []
})
export class PurchaseCategoryItemsModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { SupplierViewComponent } from './supplier-view/supplier-view.component';
import { SupplierRoutingModule } from './supplier-routing.module';
import { SupplierService } from 'src/app/services/supplier.service';


@NgModule({
  declarations: [SupplierListComponent, SupplierViewComponent],
  imports: [
    CommonModule,
    SupplierRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [SupplierService],
  exports: []
})
export class SupplierModule { }

import { Injectable } from '@angular/core';
import { ProfileModel } from '../shared/models/admin/profile.model';
import { HttpClient } from '@angular/common/http';
import { HttpRequestBaseService } from './http-request-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/profile';

  public async find(): Promise<ProfileModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(profile: ProfileModel): Promise<any> {
    return this.post({
      path: this.path,
      request: profile
    });
  }

  public async update(profile: ProfileModel): Promise<any> {
    return this.put({
      path: `${this.path}/${profile._id}`,
      request: profile
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
    });
  }

  public async getDomain(): Promise<ProfileModel[]> {
    return this.get({
      path: `${this.path}/domain`,
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserModel } from "../shared/models/admin/user.model";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class UserService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/user';

  public async find(): Promise<UserModel[]> {
    return this.get({
      path: `${this.path}?populateOnFind=profile`,
    });
  }

  public async add(user: UserModel): Promise<any> {
    return this.post({
      path: this.path,
      request: user
    });
  }

  public async update(user: UserModel): Promise<any> {
    return this.put({
      path: `${this.path}/${user._id}`,
      request: user
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
    });
  }
}
import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../../utils/local-storage';
import { TokenModel } from '../../models/common/token.model';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
})
export class LoggedComponent implements OnInit {

  public classCss = 'sidebar-collapse sidebar-closed';
  version!: string;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private eRef: ElementRef,
  ) {
    this.version = environment.appVersion;
  }

  ngOnInit(): void {
    const token = LocalStorageHelper.getItem<TokenModel>('TOKEN');

    if (!token || !token.access_token) {
      this.router.navigate(['login']);
    }
  }



  signOut(): void {
    LocalStorageHelper.removeItem('TOKEN');
    LocalStorageHelper.removeItem('USER_INFO');
    this.router.navigate(['/login']);
  }

  private isSideBarCLick(target: any): boolean {
    let response = false;

    if (target.parentNode && target.parentNode?.nodeName === 'ASIDE') {
      response = true;
    } else {
      if (target.parentNode) {
        this.isSideBarCLick(target.parentNode);
      } else {
        response = false;
      }
    }

    return response;
  }
}


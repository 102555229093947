<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h3>{{module.pluralName}}</h3>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">{{module.parentName}}</li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">

    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card card-primary card-outline card-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item" *ngFor="let dataItem of data; let i = index">
                            <a class="nav-link" [ngClass]="{ 'active': i == 0 }" id="custom-tabs-{{i}}-tab"
                                data-toggle="pill" href="#custom-tabs-{{i}}" role="tab"
                                attr.aria-controls="custom-tabs-{{i}}"
                                aria-selected="true">{{dataItem.company_name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade" [ngClass]="{ 'active show': i == 0 }"
                            *ngFor="let dataItem of data; let i = index" id="custom-tabs-{{i}}" role="tabpanel"
                            attr.aria-labelledby="custom-tabs-{{i}}-tab">



                            <div class="row">
                                <div class="col-12">
                                    <div id="accordion{{i}}">
                                        <div class="card" *ngFor="let subitem of dataItem.items; let j = index">
                                            <div class="card-header">
                                                <h4 class="card-title w-100">
                                                    <a class="d-block w-100 accordion-text"
                                                        style="text-decoration:none;" data-toggle="collapse"
                                                        href="#collapse{{i}}{{j}}">
                                                        {{subitem.category_name}}
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="collapse{{i}}{{j}}" class="collapse"
                                                attr.data-parent="#accordion{{i}}">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group"
                                                                        *ngFor="let item of subitem.items; let k = index"
                                                                        style="border-bottom: 1px solid #c0c0c0;
                                                                        padding-bottom: 29px;">
                                                                        <div class="row">
                                                                            <label
                                                                                style="font-size: 12px;">{{item.item.name}}</label>
                                                                            <label
                                                                                style="font-size: 10px !important; margin-left: 3px; font-weight: 100;">
                                                                                ({{item.item.unitType}})
                                                                            </label>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-4 col-sm-12 col-lg-4">
                                                                                <label
                                                                                    style="font-size: 10px;font-weight: 200;">Mín.</label>
                                                                                <div
                                                                                    class="input-group input-plus-minus">
                                                                                    <div class="input-group-prepend"
                                                                                        style="cursor: pointer;"
                                                                                        (click)="minusValue(i, j, k, 'minimumQuantity', $event)">
                                                                                        <span
                                                                                            class="input-group-text"><i
                                                                                                class="fas fa-minus"></i></span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [disabled]="!hasPermission(module.permission.UPDATE)"
                                                                                        (change)="change(i, j, k, 'minimumQuantity', $event)"
                                                                                        style="text-align: center;"
                                                                                        [value]="item.minimumQuantity">

                                                                                    <div class="input-group-append"
                                                                                        style="cursor: pointer;"
                                                                                        (click)="plusValue(i, j, k, 'minimumQuantity', $event)">
                                                                                        <div class="input-group-text"><i
                                                                                                class="fas fa-plus"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-4 col-sm-12 col-lg-4">
                                                                                <label
                                                                                    style="font-size: 10px;font-weight: 200;">Max.</label>
                                                                                <div
                                                                                    class="input-group input-plus-minus">
                                                                                    <div class="input-group-prepend"
                                                                                        style="cursor: pointer;"
                                                                                        (click)="minusValue(i, j, k, 'maximumQuantity', $event)">
                                                                                        <span
                                                                                            class="input-group-text"><i
                                                                                                class="fas fa-minus"></i></span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [disabled]="!hasPermission(module.permission.UPDATE)"
                                                                                        (change)="change(i, j, k, 'maximumQuantity', $event)"
                                                                                        style="text-align: center;"
                                                                                        [value]="item.maximumQuantity">

                                                                                    <div class="input-group-append"
                                                                                        style="cursor: pointer;"
                                                                                        (click)="plusValue(i, j, k, 'maximumQuantity', $event)">
                                                                                        <div class="input-group-text"><i
                                                                                                class="fas fa-plus"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>





                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="alert alert-primary" role="alert">
                                                                Ultima atualização : <b>{{subitem.updated_at}}</b>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
import { Component } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CompanyModel } from "src/app/shared/models/admin/company.model";
import { UserModel } from "src/app/shared/models/admin/user.model";
import { BaseComponent } from "../../base/base.component";
import { Toastr } from "src/app/services/toastr.service";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ProfileModel } from "src/app/shared/models/admin/profile.model";
import { Module } from "src/app/shared/models/common/module.interface";

@Component({
    template: ''
})
export abstract class UserBaseComponent extends BaseComponent {

    itemOpenedToEdit!: UserModel;
    formGroup!: FormGroup;
    profiles: ProfileModel[] = [];
    companies: CompanyModel[] = [];
    data: UserModel[] = [];
    auxData: UserModel[] = [];

    public module: Module = {
        name: 'Usuário',
        pluralName: 'Usuários',
        parentName: 'Admin',
        urlView: '/admin/user/view',
        urlList: '/admin/user/list',
        permission: {
            GET: 'ADMIN_USER_GET',
            ADD: 'ADMIN_USER_ADD',
            UPDATE: 'ADMIN_USER_UPDATE',
            DELETE: 'ADMIN_USER_DELETE'
        }
    }

    constructor(
        protected toastr: Toastr,
        protected override router: Router,
        protected override deviceService: DeviceDetectorService,
        protected confirmationModal: ConfirmationModalService
    ) {
        super(deviceService, router);
    }

    get form() {
        return this.formGroup.controls;
    }

    createForm(user: UserModel = new UserModel()) {
        this.itemOpenedToEdit = user;
        this.formGroup = new FormGroup({
            _id: new FormControl(user._id),
            email: new FormControl(user.email, [Validators.required, Validators.email]),
            full_name: new FormControl(user.full_name, Validators.required),
            phone_number: new FormControl(user.phone_number, Validators.required),
            profile: new FormControl(user.profile?._id, Validators.required),
            company: new FormControl(user.company || [], Validators.required),
            enabled: new FormControl(user.enabled),
        });
    }

    afterSave(action: string): void {
        this.toastr.success(`${this.module.name} ${action} com sucesso!`);
        this.router.navigate([this.module.urlList]);
    }

    companySelected(item: any, event: any) {
        let alreadyCompanies = this.formGroup.get('company')?.value as CompanyModel[] || [];

        if (event.target.checked) {
            alreadyCompanies.push(item);
        } else {
            alreadyCompanies = alreadyCompanies.filter(x => x._id !== item._id);
        }

        const newArray: any[] = [];

        alreadyCompanies.forEach((x) => newArray.push(x));

        this.form['company'].setValue(newArray);
        this.form['company'].markAsDirty();
    }

    public checkHasCompany(id: string) {
        return ((this.formGroup.get('company')?.value as CompanyModel[])?.find(x => x._id === id));
    }

    public formGroupToModel(): UserModel {

        this.changeTextToUppercase(this.formGroup, ['full_name']);

        return new UserModel({
            _id: this.formGroup.get('_id')?.value,
            email: this.formGroup.get('email')?.value,
            full_name: this.formGroup.get('full_name')?.value,
            phone_number: this.formGroup.get('phone_number')?.value,
            company: this.formGroup.get('company')?.value.map((x: CompanyModel) => x._id),
            profile: this.formGroup.get('profile')?.value,
            google_login: this.formGroup.get('google_login')?.value || false,
            enabled: this.formGroup.get('enabled')?.value,
        })
    }

    public async remove(): Promise<void> {
        this.confirmationModal.open();
    }
}
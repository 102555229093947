import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PurchaseItemsModel } from "../shared/models/purchase/purchase-items.model";
import { BackgroundRunningService } from "./background-running.service";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class PurchaseItemsService extends HttpRequestBaseService<any, any> {
  constructor(
    protected override http: HttpClient,
    private backgroundRunningService: BackgroundRunningService) {
    super(http);
  }

  path: string = '/v1/purchase-items';

  public async find(): Promise<PurchaseItemsModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(purchaseItems: PurchaseItemsModel): Promise<any> {
    return this.post({
      path: this.path,
      request: purchaseItems,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async update(purchaseItems: PurchaseItemsModel): Promise<any> {
    return this.put({
      path: `${this.path}/${purchaseItems._id}`,
      request: purchaseItems,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
      func: () => this.backgroundRunningService.updateStock()
    });
  }
}
<div class="content-wrapper" style="min-height: 862px;margin-left: 0;">

    <!-- <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Starter Page</h1>
                </div>
                <div class="col-sm-6">
               
                </div>
            </div>
        </div>
    </div> -->


    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <router-outlet></router-outlet>
                </div>


            </div>

        </div>
    </div>

</div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class AuthService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }
  public async login(username: string, password: string): Promise<any> {
    return this.post({
      path: '/v1/auth/login',
      request: { username: username, password: password }
    });
  }

  public async verifyEmail(id: string | null): Promise<any> {
    return this.post({
      path: `/v1/auth/verify-email/${id}`,
      request: {}
    });
  }

  public async generateVerifyEmail(email: string | null): Promise<any> {
    return this.post({
      path: `/v1/auth/generate-verify-email`,
      request: { email }
    });
  }

  public async generateRecoveryPassword(email: string | null): Promise<any> {
    return this.post({
      path: `/v1/auth/generate-recovery-password`,
      request: { email }
    });
  }

  public async changePassword(id: string | null, password: string): Promise<any> {
    return this.post({
      path: `/v1/auth/change-password/${id}`,
      request: { password }
    });
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UnLoggedComponent } from './unlogged.component';


@NgModule({
  declarations: [
    UnLoggedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [RouterModule],
  providers: [UserService]
})
export class UnLoggedModule { }

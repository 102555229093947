import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseItemsListComponent } from './purchase-items-list/purchase-items-list.component';
import { PurchaseItemsViewComponent } from './purchase-items-view/purchase-items-view.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'list', component: PurchaseItemsListComponent },
      { path: 'view', component: PurchaseItemsViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseItemsRoutingModule { }

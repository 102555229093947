import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CurrentStockQuantityModel } from "../shared/models/purchase/current-stock-quantity.model";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class CurrentStockQuantityService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/current-stock-quantity';

  public async find(): Promise<CurrentStockQuantityModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(): Promise<any> {
    return this.post({
      path: this.path,
      request: {},
      skip: true
    });
  }

  public async update(_id: string, quantityStock: CurrentStockQuantityModel): Promise<any> {
    return this.put({
      path: `${this.path}/${_id}`,
      request: {},
      skip: true
    });
  }
}
<!-- <body class="login-page" style="min-height: 386.781px;">
    <div class="login-box">
        <div class="login-logo">
            <span class="">{{text}}</span>
        </div>

        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">A senha precisa ter pelo menos 6 caracteres uma letra maíuscula e um número.
                </p>
                <form [formGroup]="formChangePassword">
                    <div class="input-group mb-3">
                        <input type="password" formControlName="password" class="form-control" placeholder="Senha" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <span *ngIf="passwordControl?.invalid && (passwordControl?.dirty || passwordControl?.touched)"
                            class="error invalid-feedback">A senha precisa ter pelo menos 8 caracteres uma letra
                            maíuscula e um número.</span>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" formControlName="confirmPassword" class="form-control"
                            placeholder="Confirme a senha" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        <span
                            *ngIf="confirmPasswordControl?.invalid && (confirmPasswordControl?.dirty || confirmPasswordControl?.touched)"
                            class="error invalid-feedback">As senhas não conferem</span>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" (click)="changePassword()"
                                [disabled]="formChangePassword.status != 'VALID'"
                                class="btn btn-primary btn-block">{{textButton}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body> -->

<body class="hold-transition login-page">
    <div [formGroup]="formGroup" class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center"> <a class="h1">
                    <img src="../../assets/logo/fleXX-logos_black_text.png" alt="fleXX"
                        style="max-height:none; width: 100px;">
                </a> </div>
            <div class="card-body">
                <div class="row" *ngIf="type">
                    <div class="col-12">
                        <p class="login-box-msg" style="font-size: 20px;"><b>Cadastrar senha</b></p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p class="login-box-msg">A senha precisa ter pelo menos 6 caracteres uma letra maíuscula e um
                            número.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <input type="password" formControlName="password" class="form-control"
                                placeholder="Senha" />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                            <span
                                *ngIf="passwordControl?.invalid && (passwordControl?.dirty || passwordControl?.touched)"
                                class="error invalid-feedback">A senha precisa ter pelo menos 8 caracteres uma letra
                                maíuscula e um número.</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <input type="password" formControlName="confirmPassword" class="form-control"
                                placeholder="Confirme a senha" />
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                            <span
                                *ngIf="confirmPasswordControl?.invalid && (confirmPasswordControl?.dirty || confirmPasswordControl?.touched)"
                                class="error invalid-feedback">As senhas não conferem</span>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <button type="submit" (click)="changePassword()" [disabled]="formGroup.status != 'VALID'"
                            class="btn btn-primary btn-block">{{textButton}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
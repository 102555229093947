import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { CompanyService } from 'src/app/services/company.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { UserViewComponent } from './user-view/user-view.component';
import { UserListComponent } from './user-list/user-list.component';

@NgModule({
  declarations: [UserViewComponent, UserListComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [CompanyService]
})
export class UserModule { }

import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { QuotationService } from 'src/app/services/quotation.service';
import { Toastr } from 'src/app/services/toastr.service';
import { Module } from 'src/app/shared/models/common/module.interface';
import { QuotationModel } from 'src/app/shared/models/purchase/quotation.model';
import { UtilsService } from 'src/app/services/utils.service';
import { BaseComponent } from 'src/app/pages/base/base.component';
import { GroupedQuotationModel } from 'src/app/shared/models/purchase/grouped-quotation.model';

@Component({
  selector: 'app-quotation-all-list',
  templateUrl: './quotation-all-list.component.html',
  styleUrls: ['./quotation-all-list.component.scss']
})
export class QuotationAllListComponent extends BaseComponent {

  public data!: GroupedQuotationModel[];
  public auxData!: QuotationModel;
  public disableConfirmButton = true;
  public _id!: string;
  search!: string;
  hasError = false;
  qtdColunas: number = 0;
  columnNames: any[] = [];

  public dinamicTable: any[] = [];

  constructor(
    protected quotationService: QuotationService,
    protected toastr: Toastr,
    protected cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override router: Router,
    private route: ActivatedRoute
  ) {
    super(deviceService, router);

    this.route.queryParams.subscribe(params => {
      this._id = params['id'];
    });
  }

  public module: Module = {
    name: 'Cotação de preços',
    pluralName: 'Cotação de preços',
    urlList: '/purchase/quotation/list',
    urlView: '',
    parentName: 'Compras',
    permission: {
      GET: 'PURCHASE_QUANTITY_STOCK_ITEMS_GET',
      ADD: 'PURCHASE_QUANTITY_STOCK_ITEMS_ADD',
      UPDATE: 'PURCHASE_QUANTITY_STOCK_ITEMS_UPDATE',
      DELETE: 'PURCHASE_QUANTITY_STOCK_ITEMS_DELETE'
    }
  }



  ngOnInit(): void {

    this.getData();
  }

  public getData(): void {
    this.quotationService.find()
      .then((quotations: any) => {


        this.cacheService.purchaseCategories().then((categories) => {
          quotations.forEach((d: any) => {
            d.category_name = categories.find(x => x._id === d.category._id)?.name;
          });

          this.data = UtilsService.groupByKey('category_name', quotations);
          this.buildTableValues();
        });
      }, (errorResponse: { status: number; error: { errors: string; }; }) => {
        this.hasError = true;
        if (errorResponse.status === 400) {
          this.toastr.error(errorResponse.error.errors);
        }
      });
  }

  buildTableValues(): void {
    let qtdLinhas = 0;

    this.data.forEach((d, idx1) => {
      this.qtdColunas = d.items.length;
      qtdLinhas = d.items[0].items.length;

      const objList: any[] = [];

      for (let i = 0; i < qtdLinhas; i++) {
        let obj: any = {
          product: d.items[0].items[i].name
        };

        let minorPrice = 0;
        let qtdePrices: number[] = [];

        d.items.forEach((item, idx2) => {


          const idxFounded = d.items.findIndex(x => x.supplier.name === item.supplier.name);

          obj.index = idx1 + idx2;

          this.columnNames.push({ parentIndex: idx1, index: idx1 + idx2, name: item.supplier.name });

          const price = d.items[idxFounded].items.find(x => x.name === d.items[0].items[i].name)?.price || '0';
          obj[`column${idx1 + idx2}`] = price;

          if (minorPrice === 0) {
            minorPrice = parseFloat(price);
          }

          if (parseFloat(price) > 0 && parseFloat(price) < minorPrice) {
            minorPrice = parseFloat(price);
          }

          if (parseFloat(price) > 0) {
            qtdePrices.push(parseFloat(price));
          }
        });

        obj['minorPrice'] = minorPrice;
        obj['qtdePrices'] = qtdePrices.length;
        obj['prices'] = qtdePrices;
        objList.push(obj);
      }

      this.dinamicTable.push({
        index: idx1,
        qtdColunas: this.qtdColunas,
        category_name: d.category_name,
        items: objList
      });

      this.columnNames = this.removeDuplicateObjects(this.columnNames);
    });
  }

  isMinorPriceDuplicate(prices: number[], minorValue: number): boolean {
    return prices.filter(x => x === minorValue).length > 1;
  }

  getColumnsByIndex(index: number) {
    return this.columnNames.filter(x => x.parentIndex === index);
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  getValue(items: any, index1: number, index2: number) {
    return items[`column${index1 + index2}`];
  }

  formatCurrencyToEnglish(value: number): string {
    const formattedValue = value.toFixed(2);

    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const finalValue = parts.join('.');

    return `R$ ${finalValue}`;
  }

  clearValueIfZero($event: any): void {
    $event.target.value = '';
  }

  removeDuplicateObjects(jsonArray: any[]): any[] {
    const uniqueObjects: any[] = [];
    const objectStringSet: Set<string> = new Set();

    for (const obj of jsonArray) {
      const objString = JSON.stringify(obj);

      if (!objectStringSet.has(objString)) {
        uniqueObjects.push(obj);
        objectStringSet.add(objString);
      }
    }

    return uniqueObjects;
  }

  // highlightMaxValueRows() {
  //   const table = document.getElementById('quotations1') as any;

  //   for (let i = 1; i < table.rows.length; i++) {
  //     let maxCellValue = 0;
  //     let maxCellIndex = -1;

  //     for (let j = 1; j < table.rows[i].cells.length; j++) {
  //       const cellValue = parseFloat(table.rows[i].cells[j].textContent);
  //       if (cellValue > maxCellValue) {
  //         maxCellValue = cellValue;
  //         maxCellIndex = j;
  //       }
  //     }

  //     for (let j = 1; j < table.rows[i].cells.length; j++) {
  //       if (j === maxCellIndex) {
  //         table.rows[i].cells[j].classList.add('highlighted');
  //       } else {
  //         table.rows[i].cells[j].classList.remove('highlighted');
  //       }
  //     }
  //   }
  // }

  // highlightMinValueRows() {
  //   const table = document.getElementById('quotations1') as any;

  //   for (let i = 1; i < table.rows.length; i++) {
  //     let minCellValue = Number.MAX_VALUE;
  //     let minCellIndex = -1;

  //     for (let j = 1; j < table.rows[i].cells.length; j++) { // Inicia a partir da segunda coluna
  //       const cellValue = parseFloat(table.rows[i].cells[j].textContent);
  //       if (cellValue < minCellValue) {
  //         minCellValue = cellValue;
  //         minCellIndex = j;
  //       }
  //     }

  //     if (minCellIndex !== -1) {
  //       for (let j = 1; j < table.rows[i].cells.length; j++) { // Inicia a partir da segunda coluna
  //         if (j === minCellIndex) {
  //           table.rows[i].cells[j].classList.add('highlighted');
  //         } else {
  //           table.rows[i].cells[j].classList.remove('highlighted');
  //         }
  //       }
  //     }
  //   }
  // }

  // encontrarMenorNumero(obj: any) {
  //   let menorNumero = null;

  //   for (const key in obj) {

  //     if (key !== 'index') {
  //       const num = this.parseBRLToFloat(obj[key]);

  //       if (typeof num === 'number' && key.includes('column')) {
  //         if (menorNumero === null || (num > 0 && num < menorNumero)) {
  //           menorNumero = num;
  //         }
  //       }
  //     }
  //   }

  //   return menorNumero === 0 ? '' : menorNumero;
  // }

  // parseBRLToFloat(valorFormatado: string): any {

  //   if (typeof valorFormatado === 'number') {
  //     return valorFormatado;
  //   }

  //   // Remove o símbolo "R$" e os separadores de milhares (vírgulas)
  //   const valorSemSimbolo = valorFormatado.replace('R$', '').replace(/,/g, '');

  //   // Substitua a vírgula decimal pelo ponto (para representação numérica válida)
  //   const valorComPonto = valorSemSimbolo.replace(',', '.');

  //   // Converte a string formatada para um número de ponto flutuante (float)
  //   const numero = parseFloat(valorComPonto);

  //   return isNaN(numero) ? valorFormatado : numero;
  // }

  // encontrarMenorNumeroUnicoMaiorQueZeroEVerificarMaior(obj: Record<string, any>): { menorUnicoMaiorQueZero: number | undefined, temMaior: boolean } {
  //   const numeros: number[] = [];
  //   const contagemNumeros: Record<number, number> = {};

  //   for (const key in obj) {
  //     if (typeof obj[key] === 'number' && !key.includes('index')) {
  //       const numero = obj[key];
  //       if (numero > 0) {  // Verifica se o número é maior que zero
  //         numeros.push(numero);
  //         contagemNumeros[numero] = (contagemNumeros[numero] || 0) + 1;
  //       }
  //     }
  //   }

  //   let menorUnicoMaiorQueZero: any = undefined;
  //   let temMaior = false;

  //   for (const numero of numeros) {
  //     if (contagemNumeros[numero] === 1) {
  //       if (!menorUnicoMaiorQueZero || numero < menorUnicoMaiorQueZero) {
  //         menorUnicoMaiorQueZero = numero;
  //       }
  //     }

  //     if (numero > menorUnicoMaiorQueZero) {
  //       temMaior = true;
  //     }
  //   }

  //   return { menorUnicoMaiorQueZero, temMaior };
  // }

}

import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { LocalStorageHelper } from '../../utils/local-storage';
import { UserInfoModel } from '../../models/admin/user-info.model';
import { DOCUMENT } from '@angular/common';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public picture: string | null = null;
  public name: string | null = null;
  public roles: any[] = [];
  public sidebarMenu: any = {};

  public userMenu!: string[];

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private userInfoService: UserInfoService) {
    this.buildSidebarMenu();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.document.body.classList.remove('sidebar-open');
      }
    });

    setInterval(() => {
      this.userInfoService.checkUpdate()
        .then((response) => {
          if (response.updated) {
            LocalStorageHelper.removeItem('TOKEN');
            LocalStorageHelper.removeItem('USER_INFO');
            this.router.navigate(['/login']);
          }
        });
    }, 1200000);
  }

  buildSidebarMenu(): void {
    this.sidebarMenu = {
      user: {
        urlList: '/admin/user/list',
        urlView: '/admin/user/view',
        method: 'ADMIN_USER_GET'
      },
      profile: {
        urlList: '/admin/profile/list',
        urlView: '/admin/profile/view',
        method: 'ADMIN_PROFILE_GET'
      },
      company: {
        urlList: '/admin/company/list',
        urlView: '/admin/company/view',
        method: 'ADMIN_COMPANY_GET'
      },
      purchaseSupplier: {
        urlList: '/purchase/supplier/list',
        urlView: '/purchase/supplier/view',
        method: 'PURCHASE_SUPPLIER_GET'
      },
      purchaseCategory: {
        urlList: '/purchase/category/list',
        urlView: '/purchase/category/view',
        method: 'PURCHASE_CATEGORY_GET'
      },
      purchaseItems: {
        urlList: '/purchase/items/list',
        urlView: '/purchase/items/view',
        method: 'PURCHASE_ITEMS_GET'
      },
      purchaseCategoryItems: {
        urlList: '/purchase/category-items/list',
        urlView: '/purchase/category-items/view',
        method: 'PURCHASE_CATEGORY_ITEMS_GET'
      },
      purchaseQuantityStockItems: {
        urlList: '/purchase/quantity-stock-items/list',
        method: 'PURCHASE_QUANTITY_STOCK_ITEMS_GET'
      },
      purchaseCurrentStockQuantity: {
        urlList: '/purchase/current-stock-quantity/list',
        method: 'PURCHASE_CURRENT_STOCK_QUANTITY_GET'
      },
      purchaseQuotation: {
        urlList: '/purchase/quotation/all/list',
        method: 'PURCHASE_QUOTATION_GET'
      },
      purchaseOrders: {
        urlList: '/purchase/orders/list',
        urlView: '/purchase/orders/view',
        method: 'PURCHASE_ORDERS_GET'
      }
    }
  }

  get adminUrls(): string[] {
    return [
      this.sidebarMenu.user.urlView,
      this.sidebarMenu.user.urlList,
      this.sidebarMenu.profile.urlView,
      this.sidebarMenu.profile.urlList,
      this.sidebarMenu.company.urlView,
      this.sidebarMenu.company.urlList
    ];
  }

  get adminMethods(): string[] {
    return [
      this.sidebarMenu.user.method,
      this.sidebarMenu.profile.method,
      this.sidebarMenu.company.method
    ];
  }

  get purchaseBasicRegistrationUrls(): string[] {
    return [
      this.sidebarMenu.purchaseCategory.urlView,
      this.sidebarMenu.purchaseCategory.urlList,
      this.sidebarMenu.purchaseItems.urlView,
      this.sidebarMenu.purchaseItems.urlList,
      this.sidebarMenu.purchaseCategoryItems.urlView,
      this.sidebarMenu.purchaseCategoryItems.urlList,
      this.sidebarMenu.purchaseQuantityStockItems.urlList
    ];
  }

  get purchaseSupplierUrls(): string[] {
    return [
      this.sidebarMenu.purchaseSupplier.urlView,
      this.sidebarMenu.purchaseSupplier.urlList
    ];
  }

  get purchaseCurrentStockQuantityUrls(): string[] {
    return [
      this.sidebarMenu.purchaseCurrentStockQuantity.urlView,
      this.sidebarMenu.purchaseCurrentStockQuantity.urlList
    ];
  }

  get purchaseQuotationUrls(): string[] {
    return [
      this.sidebarMenu.purchaseQuotation.urlList
    ];
  }

  get purchaseOrdersUrls(): string[] {
    return [
      this.sidebarMenu.purchaseOrders.urlList,
      this.sidebarMenu.purchaseOrders.urlView
    ];
  }

  get purchaseMethods(): string[] {
    return [
      this.sidebarMenu.purchaseSupplier.method,
      this.sidebarMenu.purchaseCategory.method,
      this.sidebarMenu.purchaseItems.method,
      this.sidebarMenu.purchaseCategoryItems.method,
      this.sidebarMenu.purchaseQuantityStockItems.method,
      this.sidebarMenu.purchaseCurrentStockQuantity.method,
      this.sidebarMenu.purchaseQuotation.method,
      this.sidebarMenu.purchaseOrders.method
    ];
  }

  ngOnInit(): void {
    const userInfoModel = LocalStorageHelper.getItem<UserInfoModel>('USER_INFO');
    this.roles = userInfoModel.profile.roles;
    this.picture = userInfoModel.picture;
    this.name = userInfoModel.full_name;
  }

  navigateTo(path: string): void {
    this.router.navigate([path])
  }

  enabledChild(role: string): boolean {
    return this.roles.find(x => x.name === role);
  }

  enabledParent(roles: string[]): boolean {
    const roleName = this.roles.map(x => x.name);
    return roles.some(item => roleName.includes(item));
  }

  parentActive(routes: string[]): boolean {
    return routes.includes(this.router.url);
  }

  childActive(route: string[]): boolean {
    return route.includes(this.router.url);
  }
}

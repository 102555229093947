import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
export enum ConnectionStatusEnum {
    Online,
    Offline,
}
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NetworkConnection {
    online$: Observable<boolean>;
    isConnected!: boolean;

    constructor() {
        this.online$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
    }
}

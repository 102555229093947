import { Component } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Toastr } from "src/app/services/toastr.service";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { BaseComponent } from "../../base/base.component";
import { CacheService } from "src/app/services/cache.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { ItemsModel } from "src/app/shared/models/purchase/items.model";
import { UtilsService } from "src/app/services/utils.service";
import { Module } from "src/app/shared/models/common/module.interface";
import { PurchaseItemsService } from "src/app/services/purchase-items.service";
import { PurchaseItemsModel } from "src/app/shared/models/purchase/purchase-items.model";
import { PurchaseCategoryItemsService } from "src/app/services/purchase-category-items.service";
import { PurchaseCategoryItemsModel } from "src/app/shared/models/purchase/purchase-category-items.model";
import { PurchaseCategoryService } from "src/app/services/purchase-category.service";
import { PurchaseCategoryModel } from "src/app/shared/models/purchase/purchase-category.model";

@Component({
    template: ''
})
export abstract class PurchaseCategoryItemsBaseComponent extends BaseComponent {
    public formGroup!: FormGroup;
    public data: PurchaseCategoryItemsModel[] = [];
    public disableConfirmButton = true;
    public itemOpenedToEdit!: PurchaseCategoryItemsModel;
    public categories: PurchaseCategoryModel[] = [];
    public categoriesFull: PurchaseCategoryModel[] = [];
    public categoriesLeft: PurchaseCategoryModel[] = [];
    public itemsLeft: PurchaseItemsModel[] = [];
    public items: PurchaseItemsModel[] = [];

    public module: Module = {
        name: 'Categoria x Itens',
        pluralName: 'Categoria x Itens',
        parentName: 'Compras',
        urlView: '/purchase/category-items/view',
        urlList: '/purchase/category-items/list',
        permission: {
            GET: 'PURCHASE_CATEGORY_ITEMS_GET',
            ADD: 'PURCHASE_CATEGORY_ITEMS_ADD',
            UPDATE: 'PURCHASE_CATEGORY_ITEMS_UPDATE',
            DELETE: 'PURCHASE_CATEGORY_ITEMS_DELETE'
        }
    }

    constructor(
        protected purchaseCategoryItemsService: PurchaseCategoryItemsService,
        protected toastr: Toastr,
        protected modalService: ModalService,
        protected cacheService: CacheService,
        protected confirmationModal: ConfirmationModalService,
        protected override router: Router,
        protected override deviceService: DeviceDetectorService,
        protected purchaseCategoryService: PurchaseCategoryService,
        protected purchaseItemsService: PurchaseItemsService,) {
        super(deviceService, router);
    }

    get form() {
        return this.formGroup.controls;
    }

    public afterFormSave(message: string, backToList: boolean = false): void {
        this.toastr.success(`${message} com sucesso!`);

        if (!this.isMobile()) {
            this.modalService.close();
        }

        this.formGroup.reset();
        this.createForm();

        if (this.isMobile()) {
            this.router.navigate(['purchase-category-items/list']);
        } else {
            this.getData();
        }


    }

    public createForm(purchaseCategoryItems: PurchaseCategoryItemsModel = new PurchaseCategoryItemsModel()) {

        this.categories = purchaseCategoryItems?._id ? [purchaseCategoryItems?.category] : this.categoriesLeft;

        const items = purchaseCategoryItems?._id ? purchaseCategoryItems?.items.concat(this.itemsLeft) : this.itemsLeft;

        this.formGroup = new FormGroup({
            _id: new FormControl(purchaseCategoryItems?._id),
            category: new FormControl({
                value: purchaseCategoryItems?.category?._id,
                disabled: !!purchaseCategoryItems?.category?._id
            }, Validators.required),
            items: new FormArray(
                this.getItemsFormArray(items),
                c => {
                    const atLestOneChecked = (c as FormArray).controls
                        .filter(x => x.value)?.map(x => x.value)?.filter(x => x.checked);

                    if (atLestOneChecked && atLestOneChecked.length) {
                        return null;
                    }
                    return { error: true };
                }
            )
        });
    }

    private getItemsFormArray(items: ItemsModel[]) {
        const formArray: FormControl<ItemsModel | null>[] = [];

        items?.forEach((item) => {
            formArray.push(new FormControl(item));
        });
        return formArray;
    }

    trackBy(x: any) {
        return x;
    }

    public optionAlreadySelected(item: any) {
        let response = false;

        (this.data as any).forEach((d: any) => {
            const founded = d.items?.filter((x: any) => x.item === item._id);
            if (founded && founded.length) {
                response = true;
            }
        });

        return response;
    }

    public formGroupToModel(): any {
        return new PurchaseCategoryItemsModel({
            _id: this.formGroup.get('_id')?.value,
            category: this.formGroup.get('category')?.value,
            items: this.formGroup.get('items')?.value?.filter((x: any) => x.checked),
        });
    }

    public getData(): void {
        this.cacheService.purchaseCategories()
            .then((categories) => {

                this.categories = categories;
                this.categoriesFull = categories;

                this.cacheService.purchaseItems()
                    .then((items) => {
                        this.items = items?.sort((a, b) => a.name.localeCompare(b.name));

                        this.purchaseCategoryItemsService.find()
                            .then((response) => {
                                this.data = response?.sort((a, b) => a?.category?.name?.localeCompare(b?.category?.name));
                                this.data.forEach((d) => d.items.forEach((i) => {
                                    i.checked = true;
                                    const founded = this.items.find(x => x.name === i.name);
                                    if (founded) {
                                        i.unitType = founded?.unitType;
                                        i._id = founded._id;
                                    }
                                }));

                                this.categoriesLeft = UtilsService
                                    .itemsThatNotExistInBoth(this.categories, this.data.map(x => x.category),
                                        (category1, category2) => category1._id === category2._id)?.sort((a, b) => a?.name?.localeCompare(b?.name));

                                this.itemsLeft = UtilsService
                                    .itemsThatNotExistInBoth(this.items, this.data.map(x => x.items).flat(),
                                        (items1, items2) => items1.name === items2.name);

                                console.log(this.itemsLeft);

                                this.items.forEach((item) => {
                                    item.checked = true;
                                });

                                this.itemsLeft.forEach((il) => {
                                    il.checked = false;
                                });
                            });

                    });

            });

    }


    private itemsThatNotExistInBoth<T>(
        lista1: T[],
        lista2: T[],
        comparador: (item1: T, item2: T) => boolean
    ): T[] {
        const itensNaoEncontradosEmLista2 = lista1.filter(item1 =>
            !lista2.some(item2 => comparador((item1 as any).name, (item2 as any).name))
        );

        const itensNaoEncontradosEmLista1 = lista2.filter(item2 =>
            !lista1.some(item1 => comparador((item2 as any).name, (item1 as any).name))
        );

        return [...itensNaoEncontradosEmLista1, ...itensNaoEncontradosEmLista2];
    }

    groupByKey(key: string, array: any): any[] {
        const listTmp = [];

        const grouped = array
            .reduce((hash: { [x: string]: any; }, obj: { [x: string]: string | number; }) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {});


        for (const [key, value] of Object.entries(grouped)) {
            listTmp.push({ categoryName: key, _id: grouped[key][0].category, items: grouped[key] });
        }

        return listTmp;
    }

    public remove(): void {
        this.confirmationModal.open();
    }

    chechAllPermissions($event: any, role: any, roleIdx: number): void {
        let alreadyRoles = this.formGroup.get('roles')?.value as any[] || [];
        const tmpList: any[] = [];

        role.items.forEach((item: any, idx: any) => {
            role.items[idx].checked = $event.target.checked;
        });

        this.data[roleIdx] = role;

        if ($event.target.checked) {
            alreadyRoles.push.apply(alreadyRoles, role.items);
        } else {
            this.data.forEach(r => {
                tmpList.push.apply(tmpList, r.items.filter((x: any) => x.checked));
            });

            alreadyRoles = tmpList;
        }

        this.formGroup.controls['roles'].setValue(Object.assign([], alreadyRoles));
    }

    categorySelected($event: any): void {
        this.form['category'].setValue($event.target.value || null);
        this.form['category'].markAsDirty();
    }

    public setItem($event: any, idx: number) {
        if (this.formGroup.controls['items'].value) {
            this.formGroup.controls['items'].value[idx].checked = $event.target.checked;
            this.formGroup.controls['items'].setValue(this.formGroup.controls['items'].value);
        } else {

        }
    }
}
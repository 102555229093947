import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Toastr } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { PurchaseItemsBaseComponent } from '../purchase-items-base.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PurchaseItemsService } from 'src/app/services/purchase-items.service';
import { PurchaseItemsModel } from 'src/app/shared/models/purchase/purchase-items.model';

@Component({
  selector: 'app-purchase-items-view',
  templateUrl: './purchase-items-view.component.html',
  styleUrls: ['./purchase-items-view.component.scss']
})
export class PurchaseItemsViewComponent extends PurchaseItemsBaseComponent implements OnInit {

  state: any;
  selectedItems: any[] = [];

  constructor(
    protected override purchaseItemsService: PurchaseItemsService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override router: Router,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService
  ) {
    super(purchaseItemsService, toastr, modalService, cacheService, confirmationModal, router, deviceService);

    this.state = this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {

    const data = (this.state?.['data'] as any);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.checkRoute(data?._id ? this.module.permission.UPDATE : this.module.permission.ADD);

    this.createForm((this.state?.['data'] as any) || new PurchaseItemsModel);
  }

  public onRemove(): void {
    this.selectedItems = [this.itemOpenedToEdit];
    this.confirmationModal.open();
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.purchaseItemsService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.purchaseItemsService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }

  public checkHasCompany(item: string) {
    return ((this.formGroup.get('unitType')?.value as string[])?.find(x => x === item));
  }


  // companySelected(index: number, event: any) {
  //   const unitTypes = this.formGroup.get('unitType')?.value as string[] || [];

  //   if (event.target.checked && !unitTypes.includes(this.unitTypes[index])) {
  //     unitTypes.push(this.unitTypes[index]);
  //   } else {
  //     delete unitTypes[index];
  //   }

  //   const newArray: any[] = [];

  //   unitTypes.forEach((x) => newArray.push(x));

  //   this.form['unitType'].setValue(newArray);
  //   this.form['unitType'].markAsDirty();
  // }

  companySelected(item: string, event: any) {
    let alreadyCompanies = this.formGroup.get('unitType')?.value as string[] || [];

    if (event.target.checked) {
      alreadyCompanies.push(item);
    } else {
      alreadyCompanies = alreadyCompanies.filter(x => x !== item);
    }

    const newArray: any[] = [];

    alreadyCompanies.forEach((x) => newArray.push(x));

    this.form['unitType'].setValue(newArray);
    this.form['unitType'].markAsDirty();
  }

  unitTypeSelected($event: any): void {
    this.form['unitType'].setValue($event.target.value || null);
    this.form['unitType'].markAsDirty();
  }
}

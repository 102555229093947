import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class UserInfoService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/user-info';

  public async changePassword(password: string): Promise<any> {
    return this.post({
      path: `${this.path}/change-password`,
      request: { password }
    });
  }

  public async getUserInfo(): Promise<any> {
    return this.get({
      path: `${this.path}?populateOnFind=profile,company`,
    });
  }

  public async checkUpdate(): Promise<any> {
    return this.get({
      path: `${this.path}/check-update`,
      skip: true
    });
  }
}
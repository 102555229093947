import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Toastr } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  showSuccess = false;
  showMain = false;
  formGroup!: FormGroup;
  successText = 'Aguarde você será direcionado para criar uma senha de acesso a sua conta...';
  successTitle = 'E-mail confirmado!'
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toast: Toastr) {

  }
  ngOnInit(): void {
    this.createForm();

    this.route.paramMap.subscribe(paramMap => {
      this.authService.verifyEmail(paramMap.get('id'))
        .then((response) => {
          if (response && response.change_password_id) {
            this.showSuccess = true;
            setTimeout(() => {
              this.router.navigate(['change-password', response.change_password_id], { queryParams: { type: 'new' } });
            }, 6000);
          } else {
            this.successText = 'Aguarde você será direcionado para o login...';
            this.showSuccess = true;
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 6000);
          }
        }, (error) => {
          this.showMain = true;
        });
    })
  }

  createForm(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  get form() {
    return this.formGroup.controls;
  }

  send(): void {
    this.authService.generateRecoveryPassword(this.formGroup.get('email')?.value)
      .then(() => {
        this.formGroup.reset();
        this.showSuccess = true;
        this.showMain = false;
        this.toast.success('E-mail enviado com sucesso!');
      }, () => {
        this.toast.error('Não foi possivel enviar um novo e-mail de verificação de conta');
      });
  }

  isValid(inputname: string) {

    return this.formGroup.controls[inputname].invalid &&
      (this.formGroup.controls[inputname].dirty || this.formGroup.controls[inputname].touched)
  }
}

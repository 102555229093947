import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseCategoryListComponent } from './purchase-category-list/purchase-category-list.component';
import { PurchaseCategoryViewComponent } from './purchase-category-view/purchase-category-view.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'list', component: PurchaseCategoryListComponent },
      { path: 'view', component: PurchaseCategoryViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseCategoryRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleModel } from '../shared/models/admin/role.model';
import { HttpRequestBaseService } from './http-request-base.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/role';

  public async find(): Promise<RoleModel[]> {
    return this.get({
      path: this.path,
    });
  }
}

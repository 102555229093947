import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { Router } from '@angular/router';
import { PurchaseCategoryBaseComponent } from '../purchase-category-base.component';
import { PurchaseCategoryService } from 'src/app/services/purchase-category.service';
import { PurchaseCategoryModel } from 'src/app/shared/models/purchase/purchase-category.model';

@Component({
  selector: 'app-purchase-category',
  templateUrl: './purchase-category-list.component.html',
  styleUrls: ['./purchase-category-list.component.scss']
})
export class PurchaseCategoryListComponent extends PurchaseCategoryBaseComponent {

  selectAllChecked: boolean = false;
  selectedRowIndex!: number;
  selectedItems: any[] = [];
  search!: string;

  constructor(
    protected override purchaseCategoryService: PurchaseCategoryService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService,
    protected override router: Router,
  ) {
    super(purchaseCategoryService, toastr, modalService, cacheService, confirmationModal, router, deviceService);
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  private getData(): void {
    this.purchaseCategoryService.find()
      .then((response) => {
        this.data = response;
        this.auxData = this.data;
      });
  }


  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }

  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: PurchaseCategoryModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.purchaseCategoryService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural = itemsCount > 1 ? 's' : '';

      if (count === itemsCount) {
        this.getSupplierCategories();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Categoria${plural} excluida${plural} com sucesso`);
        }
      }
    });
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });
  }

  public getSupplierCategories(): void {
    this.purchaseCategoryService.find()
      .then((response: PurchaseCategoryModel[]) => {
        this.data = response;
      });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  public addNew(): void {
    this.openView(new PurchaseCategoryModel());
  }

  public selectedItem(item: PurchaseCategoryModel) {
    this.selectedItems = [item];
  }

  async openView(data: PurchaseCategoryModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
        }
      });
  }

  public filter() {
    const keysFilter = ['name'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data = this.auxData;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data = ocurrences;
    } else {
      this.data = this.auxData;
    }
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }
}

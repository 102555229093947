import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { CompanyModel } from 'src/app/shared/models/admin/company.model';
import { Module } from 'src/app/shared/models/common/module.interface';
import { QuantityStockGroupedModel } from 'src/app/shared/models/purchase/quantity-stock-grouped.model';
import { QuantityStockModel } from 'src/app/shared/models/purchase/quantity-stock.model';
import { QuantityStockItemsService } from 'src/app/services/quantity-stock-items.service';
import { BaseComponent } from 'src/app/pages/base/base.component';

@Component({
  selector: 'app-quantity-stock-items',
  templateUrl: './quantity-stock-items-list.component.html',
  styleUrls: ['./quantity-stock-items-list.component.scss']
})
export class QuantityStockItemsListComponent extends BaseComponent {

  public data: QuantityStockGroupedModel[] = [];
  public disableConfirmButton = true;


  constructor(
    protected quantityStockItemsService: QuantityStockItemsService,
    protected toastr: Toastr,
    protected cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override router: Router,
  ) {
    super(deviceService, router);
  }

  public module: Module = {
    name: 'Quantidade mínima e máxima de estoque',
    pluralName: 'Quantidade mínima e máxima de estoque',
    urlList: '/purchase/quantity-stock/list',
    urlView: '/purchase/quantity-stock/view',
    parentName: 'Compras',
    permission: {
      GET: 'PURCHASE_QUANTITY_STOCK_ITEMS_GET',
      ADD: 'PURCHASE_QUANTITY_STOCK_ITEMS_ADD',
      UPDATE: 'PURCHASE_QUANTITY_STOCK_ITEMS_UPDATE',
      DELETE: 'PURCHASE_QUANTITY_STOCK_ITEMS_DELETE'
    }
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  plusValue(idx1: number, idx2: number, idx3: number, key: string, event: any) {

    if (!this.hasPermission(this.module.permission.UPDATE)) {
      event.stopPropagation();
      return;
    }

    const item = this.data[idx1].items[idx2].items[idx3];

    (this.data as any)[idx1].items[idx2].items[idx3][key] += 1;

    this.save(item._id, item);
  }


  minusValue(idx1: number, idx2: number, idx3: number, key: string, event: any) {
    if (!this.hasPermission(this.module.permission.UPDATE)) {
      event.stopPropagation();
      return;
    }

    const item = this.data[idx1].items[idx2].items[idx3];

    if (item.minimumQuantity >= 1) {
      (this.data as any)[idx1].items[idx2].items[idx3][key] -= 1;

      this.save(item._id, item);
    }
  }

  save(_id: string, quantityStock: QuantityStockModel): void {
    this.quantityStockItemsService.update(_id, quantityStock)
      .then((response) => {

      });
  }

  public change(idx1: number, idx2: number, idx3: number, key: string, $event: any): void {
    (this.data as any)[idx1].items[idx2].items[idx3][key] = parseInt($event.target.value);
    const item = (this.data as any)[idx1].items[idx2].items[idx3];

    this.save(item._id, item);
  }

  trackBy(x: any) {
    return x;
  }

  public optionAlreadySelected(item: any) {
    let response = false;

    (this.data as any).forEach((d: any) => {
      const founded = d.items?.filter((x: any) => x.item === item._id);
      if (founded && founded.length) {
        response = true;
      }
    });

    return response;
  }

  public getData(): void {
    this.quantityStockItemsService.find()
      .then((quantityStockItemss) => {
        let companies: CompanyModel[] = [];

        this.cacheService.companies().then((companies => {
          this.cacheService.purchaseCategories().then((categories) => {
            quantityStockItemss.forEach((minimumQuantity) => {
              const company = companies.find(x => x._id === minimumQuantity.company);
              minimumQuantity.company_name = company?.name;
              minimumQuantity.category_name = (minimumQuantity as any).category?.name;
            });

            this.data = UtilsService.groupByKey('company_name', quantityStockItemss);

            this.data.forEach((d) => {
              d.items = UtilsService.groupByKey('category_name', d.items);
              let date = '';
              d.items.forEach((item) => {
                item.items.forEach((x) => {
                  if (!date) {
                    date = new Date(x.updated_at).toISOString();
                  } else if (date && new Date(date) <= new Date(x.updated_at)) {
                    date = x.updated_at;
                  }
                });
                item.updated_at = new Date(date).toLocaleString("pt-br");
              });

            });
          });



        }));
      });
  }
}

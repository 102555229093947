import { Component } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Toastr } from "src/app/services/toastr.service";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ModalService } from "src/app/shared/components/modal/modal.service";
import { SupplierModel } from "src/app/shared/models/purchase/supplier.model";
import { BaseComponent } from "../../base/base.component";
import { CacheService } from "src/app/services/cache.service";
import { SupplierService } from "src/app/services/supplier.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { ContactModel } from "src/app/shared/models/purchase/contact.model";
import { Module } from "src/app/shared/models/common/module.interface";
import { PurchaseCategoryModel } from "src/app/shared/models/purchase/purchase-category.model";

@Component({
    template: ''
})
export abstract class SupplierBaseComponent extends BaseComponent {
    public formGroup!: FormGroup;
    public data: SupplierModel[] = [];
    public auxData: SupplierModel[] = [];
    public disableConfirmButton = true;
    public itemOpenedToEdit: any;
    public categories: PurchaseCategoryModel[] = [];
    public module: Module = {
        name: 'Fornecedor',
        pluralName: 'Fornecedores',
        parentName: 'Compras',
        urlView: '/purchase/supplier/view',
        urlList: '/purchase/supplier/list',
        permission: {
            GET: 'PURCHASE_SUPPLIER_GET',
            ADD: 'PURCHASE_SUPPLIER_ADD',
            UPDATE: 'PURCHASE_SUPPLIER_UPDATE',
            DELETE: 'PURCHASE_SUPPLIER_DELETE'
        }
    }

    constructor(
        protected supplierService: SupplierService,
        protected toastr: Toastr,
        protected modalService: ModalService,
        protected cacheService: CacheService,
        protected confirmationModal: ConfirmationModalService,
        protected override router: Router,
        protected override deviceService: DeviceDetectorService) {
        super(deviceService, router);
    }

    get form() {
        return this.formGroup.controls;
    }

    public createForm(supplier: SupplierModel = new SupplierModel()) {
        this.itemOpenedToEdit = supplier;

        supplier?.contact?.forEach((c) => {
            c.phone_number = this.phoneMask(c.phone_number)
        });

        this.formGroup = new FormGroup({
            _id: new FormControl(supplier._id),
            name: new FormControl(supplier.name, Validators.required),
            email: new FormControl(supplier.email || [''], c => {
                const atLestOneChecked = (c as FormControl).value;

                if (atLestOneChecked &&
                    atLestOneChecked.length && atLestOneChecked[0]) {
                    return null;
                }
                return { error: true };
            }),
            contact: new FormControl(supplier.contact || [new ContactModel({ name: '', phone_number: '' })],
                c => {
                    const atLestOneChecked = (c as FormControl).value;

                    if (atLestOneChecked &&
                        atLestOneChecked.length &&
                        atLestOneChecked[0].name &&
                        atLestOneChecked[0].phone_number &&
                        atLestOneChecked[0].name.length >= 3 &&
                        atLestOneChecked[0].phone_number.length > 13) {
                        return null;
                    }
                    return { error: true };
                }),
            address: new FormControl(supplier.address, Validators.required),
            category: new FormControl(supplier.category, Validators.required),
            minimumAmount: new FormControl(supplier.minimumAmount, Validators.required),
            minimumValue: new FormControl(supplier.minimumValue, Validators.required)
        });


    }

    public afterSave(action: string): void {
        this.toastr.success(`${this.module.name} ${action} com sucesso!`);
        this.router.navigate([this.module.urlList])
    }

    public formGroupToModel(): SupplierModel {

        this.changeTextToUppercase(this.formGroup, ['name', 'address']);

        return new SupplierModel({
            _id: this.formGroup.get('_id')?.value,
            name: this.formGroup.get('name')?.value,
            email: this.formGroup.get('email')?.value,
            contact: this.formGroup.get('contact')?.value,
            address: this.formGroup.get('address')?.value,
            category: this.formGroup.get('category')?.value,
            minimumAmount: this.formGroup.get('minimumAmount')?.value,
            minimumValue: this.formGroup.get('minimumValue')?.value,
        });
    }

    public remove(): void {
        this.confirmationModal.open();
    }

    public handlePhone = (event: any) => {
        let input = event.target
        input.value = this.phoneMask(input.value)
    }

    public phoneMask = (value: any) => {
        if (!value) return ""
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d)/, "($1) $2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        return value;
    }
}
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{module.parentName}}</a></li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <h2 class="card-title">Itens</h2>

            <div class="card-tools">
                <button *ngIf="hasPermission(module.name.concat('_DELETE')) && form['_id'].value" type="button"
                    class="btn btn-tool" title="Excluir" (click)="remove()">
                    <i class="fas fa-trash-alt fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form #formGroup2 [formGroup]="formGroup">
                        <div class="form-group">
                            <label>Categoria</label>
                            <select #selectcategory formControlName="category"
                                [ngClass]="{'is-invalid': form['category'].invalid && (form['category'].dirty || form['category'].touched) }"
                                (change)="categorySelected($event)" class="form-control select2bs4 select2"
                                style="width: 100%;">
                                <option *ngFor="let item of categories; let i = index" [value]="item?._id">
                                    {{item?.name}}
                                </option>
                            </select>
                            <span
                                *ngIf="form['category'].invalid && (form['category'].dirty || form['category'].touched)"
                                class="error invalid-feedback">Campo obrigátorio</span>
                        </div>

                        <fieldset class="fieldset"
                            [ngClass]="{'fieldset-error': form['category'].invalid && (form['category'].dirty || form['category'].touched) }">
                            <legend>Itens</legend>

                            <div class="row" formArrayName="items">
                                <div class="col-sm-12 col-md-12"
                                    *ngFor="let item of formGroup.get('items')?.value; let i =index; trackBy:trackBy">
                                    <div class="icheck-primary">
                                        <input type="checkbox" [checked]="formGroup.get('items')?.value[i]?.checked"
                                            (click)="setItem($event, i)" id="checkBoxitem{{i}}" />
                                        <label for="checkBoxitem{{i}}" style="font-weight: bold;">
                                            {{item?.name}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span
                                *ngIf="form['category'].invalid && (form['category'].dirty || form['category'].touched)"
                                class="error invalid-feedback">Selecione ao menos um item</span>
                        </fieldset>

                        <div class="row action-save-back" *ngIf="isMobile()">
                            <div class="col-6">
                                <button type="button" class="btn btn-default text- btn-sm pull-left"
                                    [routerLink]="[module.urlList]"><i class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    *ngIf="hasPermission(module.permission.UPDATE) && form['_id'].value || hasPermission(module.permission.ADD) && !form['_id'].value"
                                    class="btn btn-primary btn-sm btn-card-action">
                                    <i class="fa fa-save"></i> salvar
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isMobile()">
                            <div class="col-6 align-self-end ml-auto">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    class="btn btn-primary btn-sm pull-right"><i class="fa fa-save"></i>
                                    salvar</button>

                                <button type="button" class="btn btn-default btn-sm pull-right"
                                    style="margin-right: 10px;" [routerLink]="[module.urlList]"><i
                                        class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?">
</confirmation-modal>
export class PurchaseItemsModel {
  public constructor(init?: Partial<PurchaseItemsModel>) {
    Object.assign(this, init);
  }
  public _id!: string;
  public name!: string;
  public checked!: boolean;
  public unitType!: string;
  public price!: string;
  public quantity: number = 0;
  public maximum_quantity: number = 0;

}

import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Toastr } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { PurchaseCategoryItemsBaseComponent } from '../purchase-category-items-base.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PurchaseCategoryItemsService } from 'src/app/services/purchase-category-items.service';
import { PurchaseItemsService } from 'src/app/services/purchase-items.service';
import { PurchaseItemsModel } from 'src/app/shared/models/purchase/purchase-items.model';
import { PurchaseCategoryService } from 'src/app/services/purchase-category.service';
import { PurchaseCategoryModel } from 'src/app/shared/models/purchase/purchase-category.model';

@Component({
  selector: 'app-purchase-category-items-view',
  templateUrl: './purchase-category-items-view.component.html',
  styleUrls: ['./purchase-category-items-view.component.scss']
})
export class PurchaseCategoryItemsViewComponent extends PurchaseCategoryItemsBaseComponent implements OnInit {

  state: any;
  selectedItems: any[] = [];

  constructor(
    protected override purchaseCategoryItemsService: PurchaseCategoryItemsService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override router: Router,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService,
    protected override purchaseCategoryService: PurchaseCategoryService,
    protected override purchaseItemsService: PurchaseItemsService,
  ) {
    super(
      purchaseCategoryItemsService,
      toastr,
      modalService,
      cacheService,
      confirmationModal,
      router,
      deviceService,
      purchaseCategoryService,
      purchaseItemsService
    );

    this.state = this.router.getCurrentNavigation()?.extras?.state;
    this.categories = this.state?.['categories'] as PurchaseCategoryModel[];
    this.items = this.state?.['items'] as PurchaseItemsModel[];
    this.itemsLeft = this.state?.['itemsLeft'] as PurchaseItemsModel[];
    this.categoriesLeft = this.state?.['categoriesLeft'] as PurchaseCategoryModel[];

    if (!this.state) {
      this.router.navigate(['purchase-category-items/list']);
    }
  }

  ngOnInit(): void {
    const data = (this.state?.['data'] as any);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.checkRoute(data?._id ? this.module.permission.UPDATE : this.module.permission.ADD);

    this.createForm(data);
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.purchaseCategoryService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.purchaseCategoryItemsService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }

  afterSave(action: string): void {
    this.toastr.success(`${this.module.name} ${action} com sucesso!`);
    this.router.navigate([this.module.urlList]);
  }
}

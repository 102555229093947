import { CompanyModel } from "./company.model";
import { ProfileModel } from "./profile.model";
import { TenantModel } from "./tenant.model";

export class UserModel {
  public constructor(init?: Partial<UserModel>) {
    Object.assign(this, init);
  }

  public _id!: string;
  public email!: string;
  public username!: string;
  public full_name!: string;
  public phone_number!: string;
  public picture!: string;
  public google_login!: boolean;
  public company!: CompanyModel[];
  public companies_names!: string;
  public profile_name!: string;
  public profile!: ProfileModel;
  public tenant!: TenantModel;
  public checked!: boolean;
  public enabled!: boolean;
}

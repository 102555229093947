import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';


@NgModule({
  declarations: [ProfileListComponent, ProfileViewComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class ProfileModule { }

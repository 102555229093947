import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupplierViewComponent } from './supplier-view/supplier-view.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'list', component: SupplierListComponent },
      { path: 'view', component: SupplierViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule { }

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appFormatCurrency]'
})
export class FormatCurrencyDirective implements OnChanges {
    @Input() value!: number;

    constructor(private el: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value']) {
            this.el.nativeElement.textContent = this.formatCurrency(this.value);
        }
    }

    private formatCurrency(value: number): string {

        if (typeof value === "string") {
            value = parseFloat(value)
        }

        const formattedValue = value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
        return formattedValue;
    }
}
<div class="modal fade " data-backdrop="false" id="confirmation-modal">
  <div class="modal-dialog modal-xs">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" style="font-weight: 800;">{{title}}</h4>
        <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{messageText}}
      </div>
      <div class="modal-footer">
        <div class="align-self-end ml-auto">
          <button type="button" class="btn btn-default btn-sm text-right" style="margin-right: 10px;"
            data-dismiss="modal" (click)="cancel()">{{cancelButtonText}}</button>
          <button type="button" class="btn btn-primary btn-sm" (click)="confirm()">{{confirmButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PurchaseCategoryModel } from "../shared/models/purchase/purchase-category.model";
import { BackgroundRunningService } from "./background-running.service";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class PurchaseCategoryService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient, private backgroundRunningService: BackgroundRunningService) {
    super(http);
  }

  path: string = '/v1/purchase-category';

  public async find(): Promise<PurchaseCategoryModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(purchaseCategory: PurchaseCategoryModel): Promise<any> {
    return this.post({
      path: this.path,
      request: purchaseCategory,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async update(purchaseCategory: PurchaseCategoryModel): Promise<any> {
    return this.put({
      path: `${this.path}/${purchaseCategory._id}`,
      request: purchaseCategory,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
      func: () => this.backgroundRunningService.updateStock()
    });
  }
}
<div class="wrapper">
  <nav class="main-header navbar navbar-expand navbar-white bg-lightblue navbar-light text-sm">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="nav-link dropdown-toggle ">
          <i class="fas fa-cog"></i></a>
        <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow dropdown-menu-right">
          <li><a routerLink="/my-profile" class="dropdown-item"><i class="fas fa-user"></i> Perfil </a></li>


          <li class="dropdown-divider"></li>
          <li><a routerLink="/my-profile" class="dropdown-item"><i class="fas fa-info"></i> Versão {{version}} </a></li>


          <li class="dropdown-divider"></li>
          <li (click)="signOut()"><a href="#" class="dropdown-item"><i class="fas fa-sign-out-alt"></i> Sair</a></li>
        </ul>
      </li>
    </ul>
  </nav>
  <app-sidebar></app-sidebar>

  <div class="content-wrapper">
    <section class="content">
      <router-outlet></router-outlet>
    </section>
  </div>


</div>
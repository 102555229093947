import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Toastr } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  formGroup!: FormGroup;
  id!: string | null;
  text: string = 'Alterar senha';
  textButton: string = 'Alterar';
  type: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private toastr: Toastr,
    private router: Router) {
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
    });

    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.get('type')) {
        this.type = 'new';
        this.text = 'Criar uma senha';
        this.textButton = 'Salvar';
      }
    });
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      password: new FormControl('', [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl('', [Validators.required, this.confirmPasswordValidator()])
    });
  }

  get passwordControl() {
    return this.formGroup?.get('password');
  }

  get confirmPasswordControl() {
    return this.formGroup?.get('confirmPassword');
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /\d/.test(value);
      const isValid = value.length >= 8 && hasUpperCase && hasNumber;

      if (!isValid) {
        return { passwordRequirements: true };
      }

      return null;
    };
  }

  confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      const isValid = value === this.passwordControl?.value;

      if (!isValid) {
        return { passwordRequirements: true };
      }

      return null;
    };
  }

  changePassword(): void {
    this.authService.changePassword(this.id, this.passwordControl?.value)
      .then(() => {
        this.toastr.success(`Senha ${this.type ? 'criada' : 'alterarada'} com sucesso`);
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 4000);
      }, (error) => {
        this.toastr.error(`Não foi possivel ${this.type ? 'criar' : 'alterar'} a senha, tente novamente ou contate o administrador`);
      })
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { QuantityStockCategoryModel } from "../shared/models/purchase/quantity-stock-category.model";

@Injectable()
export class UtilsService {
    constructor() {

    }

    static itemsThatNotExistInBoth2<T>(lista1: T[], lista2: T[]): T[] {
        const itemsList1 = lista1.filter(item => !lista2.includes(item));

        const itemsList2 = lista2.filter(item => !lista1.includes(item));

        const itensNaoComuns = [...itemsList2, ...itemsList1];

        return itensNaoComuns;
    }

    static itemsThatNotExistInBoth<T>(
        lista1: T[],
        lista2: T[],
        comparador: (item1: T, item2: T) => boolean
    ): T[] {
        const itensNaoEncontradosEmLista2 = lista1.filter(item1 =>
            !lista2.some(item2 => comparador(item1, item2))
        );

        const itensNaoEncontradosEmLista1 = lista2.filter(item2 =>
            !lista1.some(item1 => comparador(item2, item1))
        );

        return [...itensNaoEncontradosEmLista1, ...itensNaoEncontradosEmLista2];
    }

    static groupByKey(key: string, array: any): any[] {
        const key_name = key;
        const listTmp = [];

        const grouped = array
            .reduce((hash: { [x: string]: any; }, obj: { [x: string]: string | number; }) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
            }, {});


        for (const [key, value] of Object.entries(grouped)) {
            let ob: any = {};
            ob[key_name] = key;
            ob._id = grouped[key][0]._id;
            ob.items = grouped[key];

            listTmp.push(ob);
        }

        return listTmp;
    }

    static reIndex(array: any[]) {
        let arrayTmp: any[] = [];
        array.forEach((item) => {
            arrayTmp.push(item);
        });

        return arrayTmp;
    }


    static dateFormat(data: Date): string {
        const day = String(data.getDate()).padStart(2, '0');
        const month = String(data.getMonth() + 1).padStart(2, '0');
        const year = String(data.getFullYear());
        const hour = String(data.getHours()).padStart(2, '0');
        const minutes = String(data.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hour}:${minutes}`;
    }

    static currencyFormat(valor: number): string {
        const formattedValue = valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
        });
        return formattedValue;
    }


    static findMinorValue(values: number[]): number {
        if (values.length === 0) {
            return 0;
        }

        let menor = values[0];

        for (let i = 1; i < values.length; i++) {
            if (values[i] < menor) {
                menor = values[i];
            }
        }

        return menor;
    }

    static filterItemsById(array: any[], idList: string[]): any[] {
        return array.filter(item => !idList.includes(item._id));
    }
} 
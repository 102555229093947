import { Injectable } from "@angular/core";

declare var window: any;

@Injectable({ providedIn: 'root' })
export class ModalService {

  formModal: any;
  
  open(id: string) {
    this.formModal = new window.bootstrap.Modal(document.getElementById(id), 
      { 
        backdrop: 'static',
        keyboard: true 
      });
    this.formModal.show();
  }

  close() {
    setTimeout(() => {
      this.formModal?.hide();
    }, 100);    
  }
}
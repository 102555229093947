import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'modal',
    templateUrl: 'modal.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent {
    @Input() id!: string;
    @Input() title!: string;
    @Input() confirmButtonText: string = 'Salvar';
    @Input() size: string = 'sm';
    @Input() cancelButtonText: string = 'Cancelar';
    @Input() deleteButtonText: string = 'Excluir';
    @Input() disableConfirmButton: boolean = true;
    @Input() showConfirmButton: boolean = true;
    @Input() showRemoveButton: boolean = true;
    @Output() onCancel = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<void>();
    @Output() onRemove = new EventEmitter<void>();


    cancel(): void {
        this.onCancel.emit();
    }

    confirm(): void {
        this.onConfirm.emit();
    }

    remove(): void {
        this.onRemove.emit();
    }
}
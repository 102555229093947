import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SupplierModel } from 'src/app/shared/models/purchase/supplier.model';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SupplierBaseComponent } from '../supplier-base.component';
import { CacheService } from 'src/app/services/cache.service';
import { Router } from '@angular/router';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent extends SupplierBaseComponent {

  selectAllChecked: boolean = false;
  selectedRowIndex!: number;
  selectedItems: any[] = [];
  search!: string;

  constructor(
    protected override supplierService: SupplierService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService,
    protected override router: Router,
  ) {
    super(supplierService, toastr, modalService, cacheService, confirmationModal, router, deviceService);
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }


  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: SupplierModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.supplierService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural1 = itemsCount > 1 ? 'ns' : 'm';
      const plural2 = itemsCount > 1 ? 's' : '';

      if (count === itemsCount) {
        this.getData();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Ite${plural1} excluido${plural2} com sucesso`);
        }
      }
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });
  }

  public getData(): void {
    this.supplierService.find()
      .then((response) => {
        this.cacheService.purchaseCategories().then((categories) => {
          this.categories = categories;
          this.data = response?.sort((a, b) => a.name.localeCompare(b.name));
          this.data.forEach((d) => {
            d.category_name = this.categories.find(x => x._id === d.category)?.name || '';
            d.address_truncated = d.address.length > 50 ? d.address.substring(0, 50) + '...' : d.address;
          });
          this.auxData = this.data;
        });
      });
  }

  public addNew(): void {
    this.openView(new SupplierModel());
  }

  public selectedItem(item: SupplierModel) {
    this.selectedItems = [item];
  }

  public openView(data: SupplierModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
          categories: this.categories
        }
      });
  }

  public filter() {
    const keysFilter = ['name', 'category_name', 'address'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data = this.auxData;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data = ocurrences;
    } else {
      this.data = this.auxData;
    }
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }
}

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Admin</a></li>
                    <li class="breadcrumb-item active">Itens</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h2 class="card-title card-title-center">{{module.name}}</h2>
                </div>
                <div class="col-6">
                    <button
                        [style.visibility]="hasPermission(module.permission.DELETE) && form['_id'].value ? 'visible' : 'hidden'"
                        [disabled]="" type="button" class="btn btn-danger btn-sm btn-card-action" title="Excluir"
                        (click)="remove()">
                        <i class="fas fa-trash-alt fa-sm"></i> excluir
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form #formGroup2 [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="name">Nome</label>
                                    <input type="text" #name class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['name'].invalid && (form['name'].dirty || form['name'].touched) }"
                                        formControlName="name" />
                                    <span *ngIf="form['name'].invalid && (form['name'].dirty || form['name'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">

                                <div class="form-group">
                                    <label>Perfil</label>
                                    <select formControlName="unitType"
                                        [ngClass]="{'is-invalid': form['unitType'].invalid && (form['unitType'].dirty || form['unitType'].touched) }"
                                        (change)="unitTypeSelected($event)" class="form-control select2bs4 select2"
                                        style="width: 100%;">
                                        <option *ngFor="let item of unitTypes; let i = index" [value]="item">
                                            {{item}}
                                        </option>
                                    </select>

                                    <span
                                        *ngIf="form['unitType'].invalid && (form['unitType'].dirty || form['unitType'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>


                        <div class="row action-save-back" *ngIf="isMobile()">
                            <div class="col-6">
                                <button type="button" class="btn btn-default text- btn-sm pull-left"
                                    [routerLink]="[module.urlList]"><i class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    *ngIf="hasPermission(module.permission.UPDATE) && form['_id'].value || hasPermission(module.permission.ADD) && !form['_id'].value"
                                    class="btn btn-primary btn-sm btn-card-action">
                                    <i class="fa fa-save"></i> salvar
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isMobile()">
                            <div class="col-6 align-self-end ml-auto">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    class="btn btn-primary btn-sm pull-right"><i class="fa fa-save"></i>
                                    salvar</button>

                                <button type="button" class="btn btn-default btn-sm pull-right"
                                    style="margin-right: 10px;" [routerLink]="[module.urlList]"><i
                                        class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?">
</confirmation-modal>
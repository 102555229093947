<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{module.parentName}}</a></li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h2 class="card-title card-title-center">{{module.name}}</h2>
                </div>
                <div class="col-6">
                    <button
                        [style.visibility]="hasPermission(module.permission.DELETE) && form['_id'].value ? 'visible' : 'hidden'"
                        [disabled]="" type="button" class="btn btn-danger btn-sm btn-card-action" title="Excluir"
                        (click)="remove()">
                        <i class="fas fa-trash-alt fa-sm"></i> excluir
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form #formGroup2 [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="full_name">Nome completo</label>
                                    <input type="text" class="form-control uppercase" #full_name
                                        [ngClass]="{'is-invalid': form['full_name'].invalid && (form['full_name'].dirty || form['full_name'].touched) }"
                                        formControlName="full_name" />
                                    <span
                                        *ngIf="form['full_name'].invalid && (form['full_name'].dirty || form['full_name'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label" for="email">E-mail</label>
                                    <input type="text" #email class="form-control"
                                        [ngClass]="{'is-invalid': form['email'].invalid && (form['email'].dirty || form['email'].touched) }"
                                        formControlName="email" />
                                    <span
                                        *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label" for="phone_number">Fone:</label>
                                    <input type="text" mask="(00) 00000-0000" #phone_number
                                        [ngClass]="{'is-invalid': form['phone_number'].invalid && (form['phone_number'].dirty || form['phone_number'].touched) }"
                                        class="form-control" data-inputmask="'mask': '(99) 99999-9999'" data-mask=""
                                        inputmode="text" formControlName="phone_number" />
                                    <span
                                        *ngIf="form['phone_number'].invalid && (form['phone_number'].dirty || form['phone_number'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Perfil</label>
                                    <select formControlName="profile"
                                        [ngClass]="{'is-invalid': form['profile'].invalid && (form['profile'].dirty || form['profile'].touched) }"
                                        (change)="profileSelected($event)" class="form-control select2bs4 select2"
                                        style="width: 100%;">
                                        <option *ngFor="let item of profiles; let i = index" [value]="item._id">
                                            {{item.name}}
                                        </option>
                                    </select>

                                    <span
                                        *ngIf="form['profile'].invalid && (form['profile'].dirty || form['profile'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <fieldset class="fieldset-user" style="margin-bottom: 1.3rem;"
                                    [ngClass]="{'fieldset-error': form['company'].invalid && (form['company'].dirty || form['company'].touched) }">
                                    <legend>Empresas</legend>

                                    <div class="form-group clearfix">
                                        <div class="icheck-primary" *ngFor="let item of companies; let i = index">
                                            <input type="checkbox" [disabled]="!hasPermission(module.permission.UPDATE)"
                                                [checked]="checkHasCompany(item._id)" id="checkboxCompany{{i}}"
                                                (click)="companySelected(item, $event)" />
                                            <label for="checkboxCompany{{i}}">
                                                {{item.name}}
                                            </label>
                                        </div>
                                    </div>
                                    <span
                                        *ngIf="form['company'].invalid && (form['company'].dirty || form['company'].touched)"
                                        class="error invalid-feedback">Selecione ao menos uma empresa</span>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group clearfix" style="margin-top: 5px;" *ngIf="form['_id']?.value">
                                    <div class="icheck-primary">
                                        <input type="checkbox" id="checkboxActive" [checked]="form['enabled'].value"
                                            formControlName="enabled" />
                                        <label for="checkboxActive">
                                            Ativo
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row action-save-back" *ngIf="isMobile()">
                            <div class="col-6">
                                <button type="button" class="btn btn-default text- btn-sm pull-left"
                                    [routerLink]="[module.urlList]"><i class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    *ngIf="hasPermission(module.permission.UPDATE) && form['_id'].value || hasPermission(module.permission.ADD) && !form['_id'].value"
                                    class="btn btn-primary btn-sm btn-card-action">
                                    <i class="fa fa-save"></i> salvar
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isMobile()">
                            <div class="col-6 align-self-end ml-auto">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    class="btn btn-primary btn-sm pull-right"><i class="fa fa-save"></i>
                                    salvar</button>

                                <button type="button" class="btn btn-default btn-sm pull-right"
                                    style="margin-right: 10px;" [routerLink]="[module.urlList]"><i
                                        class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?"> </confirmation-modal>
import { ContactModel } from "./contact.model";

export class SupplierModel {
  public constructor(init?: Partial<SupplierModel>) {
    Object.assign(this, init);
  }

  public _id!: string;
  public name!: string;
  public contact!: ContactModel[];
  public email!: string[];
  public category!: string;
  public category_name!: string;
  public address_truncated!: string;
  public address!: string;
  public checked!: boolean;
  public minimumValue!: number;
  public minimumAmount!: number;
}

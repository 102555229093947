import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NetworkConnection } from './services/network-connection.service';
import { NavigationEnd, NavigationError, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  constructor(
    private detector: DeviceDetectorService,
    private networkConnection: NetworkConnection,
    private router: Router) {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd || event instanceof NavigationError) {
        if (detector.isMobile()) {
          console.log(this.router.url);

          const isRunningInsideEmbedded = (window as any)['Android']?.isRunningInsideEmbedded();
          console.log(this.router.url);
          if (!isRunningInsideEmbedded && !this.router.url.includes('/purchase/quotation')) {
            this.router.navigate(['/error/not-app-installed']);
          }
        }
      }
    });




    this.networkStatus();
  }

  title = 'fleXX';

  public networkStatus() {
    this.networkConnection.online$.subscribe((value) => {
      if (!value) {
        this.router.navigate(['/error/lost-connection'])
      }
    });
  }
}

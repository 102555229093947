<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h3>{{module.pluralName}}</h3>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">{{module.parentName}}</li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">

    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card card-primary card-outline card-tabs">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <div class="input-group input-group-sm" style="border: 0.5px solid #ced4da;">
                                <input type="text" class="form-control" [(ngModel)]="search" (keyup)="filter()"
                                    style="border: none;">
                                <span class="input-group-append">
                                    <button *ngIf="!search" type="button" class="btn  btn-flat"><i
                                            class="fas fa-search fa-fw"></i></button>
                                    <button *ngIf="!!search" (click)="clearSearch()" type="button"
                                        class="btn  btn-flat"><i class="fas fa-times"></i></button>

                                </span>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <button type="button" class="btn btn-success btn-md btn-card-action"
                                (click)="loadLastQuotation()" title="Preencher com valores da ultima cotação">
                                <i class="fas fa-retweet"></i> Preencher com valores da ultima cotação
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-info alert-dismissible">
                                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                                <h5><i class="icon fas fa-info"></i> Informação!</h5>
                                Os valores são salvos automaticamente após digitá-los, clique no botão [FINALIZAR]
                                somente
                                quando finalizar de preencher a cotação.
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngFor="let item of data?.items; let i = index" style="border-bottom: 1px solid #c0c0c0;
                    padding-bottom: 20px;padding-top: 20px;">
                        <div class="col-12">
                            <div class="row">
                                <label style="font-size: 10px;">{{item.name}}</label>
                                <label style="font-size: 10px !important; margin-left: 3px; font-weight: 100;">
                                    ({{item.unitType}})
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <!-- <div class="col-md-2 col-sm-4 col-lg-2">
                                    <div class="form-group">
                                        <label style="font-size: 10px;" for="unit{{i}}">Tipo unidade</label>
                                        <select [disabled]=" item.unitType.length == 1"
                                            tabindex="{{(data.items.length || 0) + i}}" id="unit{{i}}"
                                            class="form-control select2bs4 select2" style="width: 100%;">
                                            <option *ngFor="let unit of item.unitType" [value]="unit">
                                                {{unit}}
                                            </option>
                                        </select>

                                    </div>
                                </div> -->
                                <div class="col-md-2 col-sm-4 col-lg-2">
                                    <div class="form-group">
                                        <label>Preço</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">R$</span>
                                            </div>
                                            <input tabindex="{{i + 1}}" type="text" id="price{{i}}"
                                                (click)="clearValueIfZero($event)" (keyup)="keyup($event, i)"
                                                (focus)="clearValueIfZero($event)" (change)="changeValue(i)"
                                                (focusout)="focusout($event, i)" class="form-control uppercase"
                                                mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','"
                                                [dropSpecialCharacters]="true" [leadZero]="true" [value]="item.price" />
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12" style="padding-top: 20px;">
                            <button type="button" *ngIf="!hasError" (click)="onConfirm()"
                                class="btn btn-primary btn-md pull-right"><i class="fa fa-save"></i>
                                FINALIZAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import { Injectable } from "@angular/core";

declare var window: any;

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {

  formModal: any;
  
  open() {
    this.formModal = new window.bootstrap.Modal(document.getElementById('confirmation-modal'), 
      { 
        backdrop: 'static',
        keyboard: true 
      });
    this.formModal.show();
  }

  close() {
    setTimeout(() => {
      this.formModal.hide();
    }, 100);    
  }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseCategoryItemsListComponent } from './purchase-category-items-list/purchase-category-items-list.component';
import { PurchaseCategoryItemsViewComponent } from './purchase-category-items-view/purchase-category-items-view.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'list', component: PurchaseCategoryItemsListComponent },
      { path: 'view', component: PurchaseCategoryItemsViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseCategoryItemsRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { ConfirmationModalModule } from 'src/app/shared/components/confirmation-modal/confirmation-modal.module';
import { QuotationListComponent } from './quotation/quotation-list.component';
import { quotationRoutingModule } from './quotation-routing.module';
import { QuotationService } from 'src/app/services/quotation.service';
import { QuotationAllListComponent } from './quotation-all-list/quotation-all-list.component';
import { FormatCurrencyDirective } from 'src/app/shared/directives/format-currency-directive';
import { StickyDirectiveModule } from 'src/app/shared/directives/sticky.directive';

@NgModule({
  declarations: [QuotationListComponent, QuotationAllListComponent, FormatCurrencyDirective],
  imports: [
    CommonModule,
    StickyDirectiveModule,
    quotationRoutingModule,
    FormsModule,
    ModalModule,
    ConfirmationModalModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [QuotationService],
  exports: [FormatCurrencyDirective]
})
export class QuotationModule { }

import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Module } from 'src/app/shared/models/common/module.interface';
import { QuotationService } from 'src/app/services/quotation.service';
import { QuotationModel } from 'src/app/shared/models/purchase/quotation.model';
import { BaseComponent } from 'src/app/pages/base/base.component';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss']
})
export class QuotationListComponent extends BaseComponent {

  public data!: QuotationModel;
  public auxData!: QuotationModel;
  public disableConfirmButton = true;
  public _id!: string;
  search!: string;
  hasError = false;

  constructor(
    protected quotationService: QuotationService,
    protected toastr: Toastr,
    protected cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override router: Router,
    private route: ActivatedRoute
  ) {
    super(deviceService, router);

    this.route.queryParams.subscribe(params => {
      this._id = params['id'];
    });
  }

  public module: Module = {
    name: 'Cotação de preços',
    pluralName: 'Cotação de preços',
    urlList: '/purchase/quotation/list',
    urlView: '',
    parentName: 'Compras',
    permission: {
      GET: 'PURCHASE_QUANTITY_STOCK_ITEMS_GET',
      ADD: 'PURCHASE_QUANTITY_STOCK_ITEMS_ADD',
      UPDATE: 'PURCHASE_QUANTITY_STOCK_ITEMS_UPDATE',
      DELETE: 'PURCHASE_QUANTITY_STOCK_ITEMS_DELETE'
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }

  trackBy(x: any) {
    return x;
  }

  public getData(): void {
    this.quotationService.findOne(this._id)
      .then((quotations: any) => {
        this.data = quotations;
        setTimeout(() => {
          this.data.items.forEach((item, i) => {
            item.price = item.price === '0' ? '0,00' : item.price.toString().replace('.', ',');
          });
          this.auxData = Object.assign([], this.data);
        }, 100);
      }, (errorResponse) => {
        this.hasError = true;
        if (errorResponse.status === 400) {
          this.toastr.error(errorResponse.error.errors);
        }
      });
  }

  loadLastQuotation(): void {
    this.quotationService.last(this.data._id)
      .then((response) => {

        this.data.items.forEach((item, index) => {
          this.data.items[index].price = response.items.find(x => x._id.toString() === item._id.toString())?.price || '0';
          this.data.items[index].unitType = response.items.find(x => x._id.toString() === item._id.toString())?.unitType || '';
        });

        this.auxData.items.forEach((item, index) => {
          this.auxData.items[index].price = response.items.find(x => x._id.toString() === item._id.toString())?.price || '0';
          this.auxData.items[index].unitType = response.items.find(x => x._id.toString() === item._id.toString())?.unitType || '';
        });
      });
  }

  changeValue(index: number): void {
    const item = this.data.items[index];

    const inputElement = document.getElementById(`unit${index}`) as HTMLInputElement;

    this.quotationService.update(this._id, {
      _id: item._id,
      name: item.name,
      unitType: item.unitType,
      price: parseFloat(item.price.replace(',', '.'))
    })
      .then(() => {
        this.auxData.items[index].price = this.data.items[index].price;
      });
  }

  clearValueIfZero($event: any): void {
    $event.target.value = '';
  }

  keyup($event: any, index: number): void {

    this.data.items[index].price = $event.target.value;

    index += 1;

    if ($event.keyCode === 13) {
      const inputElement = document.getElementById(`price${index}`) as HTMLInputElement;
      inputElement.focus();
    }
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public filter() {
    const keysFilter = ['name'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data.items = this.auxData.items;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData.items) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data.items = ocurrences;
    } else {
      this.data.items = this.auxData.items;
    }
  }

  public focusout($event: any, index: number): void {
    $event.target.value = this.auxData.items[index].price;
  }

  public onConfirm(): void {
    this.quotationService.finalize(this.data._id)
      .then(() => {
        this.toastr.success("Cotação finalizada com sucesso!")
      }, () => {
        this.toastr.error("Erro ao finalizar cotação, se persistir o erro informe para o solicitante da cotação")
      });
  }
}

<!-- <section class="content" *ngIf="showSuccess">
    <div class="verify-email-page">
        <div class="verify-email-content">            
            <h3><i class="fas fa-check fa-lg text-success"></i> {{successTitle}}</h3>
            <p>{{successText}}</p>
        </div>
    </div>
</section>


<section class="content" *ngIf="showError">
    <div class="verify-email-page">
        <div class="verify-email-content">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Esse link não é válido.</h3>
            <p>Informe seu endereço de e-mail abaixo que enviaremos um novo link.</p>
            <form class="search-form" [formGroup]="formEmail">
                <div class="input-group">
                    <input type="text" name="email" formControlName="email" class="form-control" placeholder="e-mail" />
                    
                    <div class="input-group-append">
                        <button type="submit" [disabled]="form['email'].invalid" name="submit" (click)="send()" class="btn btn-primary">Enviar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section> -->

<body class="hold-transition login-page">
    <div [formGroup]="formGroup" class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center"> <a class="h1">
                    <img src="../../assets/logo/fleXX-logos_black_text.png" alt="fleXX"
                        style="max-height:none; width: 100px;">
                </a> </div>
            <div class="card-body" *ngIf="!showError">
                <p class="login-box-msg" style="font-size: 20px;color:green"><b>{{successTitle}}</b></p>
                <p class="login-box-msg" [innerHtml]="successText"></p>
            </div>

            <div class="card-body" *ngIf="showError">
                <p class="login-box-msg" style="font-size: 20px;color:red"><b>{{errorTitle}}</b></p>
                <p class="login-box-msg" [innerHtml]="errorText"></p>
                <div class="input-group mb-3">

                    <input type="email"
                        [ngClass]="{'is-invalid': form['email'].invalid && (form['email'].dirty || form['email'].touched) }"
                        formControlName="email" class="form-control" placeholder="e-mail">
                    <div class="input-group-append">
                        <div class="input-group-text"> <span class="fas fa-envelope"></span> </div>
                    </div>
                    <div *ngIf="isValid('email')" id="exampleInputEmail1-error" class="error invalid-feedback">Campo
                        obrigatório</div>
                </div>
                <div class="social-auth-links text-center mt-2 mb-3">
                    <button type="submit" [disabled]="form['email'].invalid" class="btn btn-block btn-primary"
                        (click)="send()" style="margin-bottom: 15px;"> Enviar </button>
                </div>
            </div>
        </div>
    </div>
</body>
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";

@Component({ 
    selector: 'confirmation-modal', 
    templateUrl: 'confirmation-modal.component.html', 
    encapsulation: ViewEncapsulation.None
})
export class ConfirmationModalComponent {
    @Input() id!: string;
    @Input() title: string = 'Confirmação';
    @Input() confirmButtonText: string = 'OK';
    @Input() messageText: string = '';
    @Input() cancelButtonText: string = 'Cancelar';
    @Output() onCancel = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<void>();


    cancel(): void {
        this.onCancel.emit();
    }

    confirm(): void {
        this.onConfirm.emit();
    }
}
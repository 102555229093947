import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { Router } from '@angular/router';
import { PurchaseItemsBaseComponent } from '../purchase-items-base.component';
import { PurchaseItemsService } from 'src/app/services/purchase-items.service';
import { PurchaseItemsModel } from 'src/app/shared/models/purchase/purchase-items.model';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-purchase-items',
  templateUrl: './purchase-items-list.component.html',
  styleUrls: ['./purchase-items-list.component.scss']
})
export class PurchaseItemsListComponent extends PurchaseItemsBaseComponent {

  selectAllChecked: boolean = false;
  selectedRowIndex!: number;
  selectedItems: any[] = [];
  search!: string;

  lista = [
    { name: 'FILET MIGNON', unitType: 'KILO' },
    { name: 'COSTELA DE PORCO', unitType: 'KILO' },
    { name: 'PEITO DE FRANGO', unitType: 'KILO' },
    { name: 'LOMBO DE PORCO', unitType: 'KILO' },
    { name: 'ASINHA DE FRANGO', unitType: 'KILO' },
    { name: 'PANCETA', unitType: 'KILO' },
    { name: 'CALABRESA', unitType: 'KILO' },
    { name: 'PATINHO/COCHÃO MOLE', unitType: 'KILO' },
    { name: 'CARNE MOIDA', unitType: 'KILO' },
    { name: 'COXA SOBRECOXA', unitType: 'KILO' },
    { name: 'LINGUIÇA TOSCANA', unitType: 'KILO' },
    { name: 'SALSICHA', unitType: 'KILO' },
    { name: 'FRANGO PASSARINHO', unitType: 'KILO' },
    { name: 'COSTELINHO PORCO', unitType: 'KILO' },
    { name: 'COSTELA VACA MAGRA', unitType: 'KILO' },
    { name: 'MUSSARELA', unitType: 'KILO' },
    { name: 'MUSSARELA PEÇA', unitType: 'UNIDADE' },
    { name: 'BACON FATIADO', unitType: 'KILO' },
    { name: 'AZEITE MISTO - 1 LITRO', unitType: 'UNIDADE' },
    { name: 'BATATA FRITA', unitType: 'UNIDADE' },
    { name: 'CACAU EM PO', unitType: 'UNIDADE' },
    { name: 'CALDA DE CHOCOLATE', unitType: 'UNIDADE' },
    { name: 'CALDO DE CARNE', unitType: 'UNIDADE' },
    { name: 'CALDO DE GALINHA', unitType: 'UNIDADE' },
    { name: 'CHÁ DE ESPECIARIAS', unitType: 'UNIDADE' },
    { name: 'CREME DE LEITE', unitType: 'UNIDADE' },
    { name: 'CHANTILLY', unitType: 'UNIDADE' },
    { name: 'DOCE DE LEITE FRIMESA', unitType: 'UNIDADE' },
    { name: 'EXTRATO DE TOMATE', unitType: 'UNIDADE' },
    { name: 'FARINHA DE TRIGO', unitType: 'UNIDADE' },
    { name: 'FARINHA DE TRIGO FARDO', unitType: 'UNIDADE' },
    { name: 'GAS DE MASSARICO', unitType: 'UNIDADE' },
    { name: 'BICO DE MASSARICO', unitType: 'UNIDADE' },
    { name: 'GELEIA DE AMORA', unitType: 'UNIDADE' },
    { name: 'GELEIA DE MENTA', unitType: 'UNIDADE' },
    { name: 'GELEIA DE PIMENTA', unitType: 'UNIDADE' },
    { name: 'GELEIA MORANGO', unitType: 'UNIDADE' },
    { name: 'GORDURA BALDE', unitType: 'UNIDADE' },
    { name: 'GORGONZOLA', unitType: 'KILO' },
    { name: 'MAIONESE BALDE', unitType: 'UNIDADE' },
    { name: 'MANTEIGA BARRA', unitType: 'UNIDADE' },
    { name: 'PIMENTA DO REINO', unitType: 'UNIDADE' },
    { name: 'QUEIJO BRIE', unitType: 'KILO' },
    { name: 'SAL', unitType: 'UNIDADE' },
    { name: 'VINHO BRANCO SECO CHALISE', unitType: 'UNIDADE' },
    { name: 'VINHO TINTO SECO CHALISE', unitType: 'UNIDADE' },
    { name: 'AÇUCAR', unitType: 'UNIDADE' },
    { name: 'AÇUCAR FARDO', unitType: 'UNIDADE' },
    { name: 'AMIDO DE MILHO(maizena)', unitType: 'UNIDADE' },
    { name: 'ARROZ NEGRO', unitType: 'UNIDADE' },
    { name: 'AZEITE TRUFADO', unitType: 'UNIDADE' },
    { name: 'AZEITONA SEM CAROÇO', unitType: 'UNIDADE' },
    { name: 'BISCOITO DE MAISENA', unitType: 'UNIDADE' },
    { name: 'CAFÉ CAPSULA NESCAFÉ "LOR"', unitType: 'UNIDADE' },
    { name: 'CAFÉ FUNCIONÁRIOS', unitType: 'UNIDADE' },
    { name: 'CAFÉ FUNCIONÁRIOS FARDO', unitType: 'UNIDADE' },
    { name: 'COCO RALADO', unitType: 'UNIDADE' },
    { name: 'EMULSIFICANTE', unitType: 'UNIDADE' },
    { name: 'ESSENCIA DE BAUNILHA', unitType: 'UNIDADE' },
    { name: 'ESSENCIA DE COCO', unitType: 'UNIDADE' },
    { name: 'ESSENCIA DE LARANJA', unitType: 'UNIDADE' },
    { name: 'FEIJÃO', unitType: 'UNIDADE' },
    { name: 'FEIJÃO FARDO', unitType: 'UNIDADE' },
    { name: 'FLOR DE SAL', unitType: 'UNIDADE' },
    { name: 'LEITE CONDENSADO 350g', unitType: 'UNIDADE' },
    { name: 'LEITE DE COCO', unitType: 'UNIDADE' },
    { name: 'MACARRÃO FUNCIONÁRIOS', unitType: 'UNIDADE' },
    { name: 'OLÉO', unitType: 'UNIDADE' },
    { name: 'OVO DUZIA', unitType: 'UNIDADE' },
    { name: 'PARMESÃO', unitType: 'KILO' },
    { name: 'PIMENTA TABASCO', unitType: 'UNIDADE' },
    { name: 'VINAGRE BALSAMICO', unitType: 'UNIDADE' },
    { name: 'ARROZ', unitType: 'UNIDADE' },
    { name: 'CAFÉ', unitType: 'UNIDADE' },
    { name: 'MACARRÃO', unitType: 'UNIDADE' },
    { name: 'AGUA SEM GÁS FARDO', unitType: 'UNIDADE' },
    { name: 'AGUA COM GÁS FARDO', unitType: 'UNIDADE' },
    { name: 'AGUA TONICA PRATA FARDO', unitType: 'UNIDADE' },
    { name: 'AGUA TONICA PRATA ZERO FARDO', unitType: 'UNIDADE' },
    { name: 'COCA COLA KS', unitType: 'UNIDADE' },
    { name: 'COCA COLA KS ZERO', unitType: 'UNIDADE' },
    { name: 'GUARANÁ', unitType: 'UNIDADE' },
    { name: 'GUARANÁ ZERO', unitType: 'UNIDADE' },
    { name: 'SPRITE', unitType: 'UNIDADE' },
    { name: 'SCHWEPPES CITRUS LATA', unitType: 'UNIDADE' },
    { name: 'SCHWEPPES CITRUS 1,5L', unitType: 'UNIDADE' },
    { name: 'TONICA 1,5L', unitType: 'UNIDADE' },
    { name: 'BUDWEISER', unitType: 'UNIDADE' },
    { name: 'STELLA ARTOIS', unitType: 'UNIDADE' },
    { name: 'HEINEKEN', unitType: 'UNIDADE' },
    { name: 'HEINEKEN ZERO', unitType: 'UNIDADE' },
    { name: 'AGUA DE COCO 1L', unitType: 'UNIDADE' },
    { name: 'ANISIO SANTIAGO', unitType: 'UNIDADE' },
    { name: 'APEROL', unitType: 'UNIDADE' },
    { name: 'BAYLERS', unitType: 'UNIDADE' },
    { name: 'BELVEDERE', unitType: 'UNIDADE' },
    { name: 'CACHAÇA 51 RESERVA', unitType: 'UNIDADE' },
    { name: 'CACHAÇA SAGATIBA', unitType: 'UNIDADE' },
    { name: 'CAMPARI', unitType: 'UNIDADE' },
    { name: 'CHIVAS 12 ANOS', unitType: 'UNIDADE' },
    { name: 'CIROC', unitType: 'UNIDADE' },
    { name: 'CYNAR', unitType: 'UNIDADE' },
    { name: 'ESPIRITO DE MINAS', unitType: 'UNIDADE' },
    { name: 'ESPUMANTE PRO SECO', unitType: 'UNIDADE' },
    { name: 'GIN DRACO', unitType: 'UNIDADE' },
    { name: 'GIN TANQUERAY', unitType: 'UNIDADE' },
    { name: 'GREY GOOSE', unitType: 'UNIDADE' },
    { name: 'LICOR 43', unitType: 'UNIDADE' },
    { name: 'LICOR COINTREAU', unitType: 'UNIDADE' },
    { name: 'LICOR DE CEREJA', unitType: 'UNIDADE' },
    { name: 'LICOR DE ROSAS', unitType: 'UNIDADE' },
    { name: 'LIMONCELLO', unitType: 'UNIDADE' },
    { name: 'MEL', unitType: 'UNIDADE' },
    { name: 'RUM BACARDI BRANCO', unitType: 'UNIDADE' },
    { name: 'RUM BACARDI OURO', unitType: 'UNIDADE' },
    { name: 'RUM MALIBU DE COCO', unitType: 'UNIDADE' },
    { name: 'RUM MONTILA OURO', unitType: 'UNIDADE' },
    { name: 'SAQUE AZUMA KIRIN DOURADO', unitType: 'UNIDADE' },
    { name: 'SAQUE AZUMA KIRIN SOFT', unitType: 'UNIDADE' },
    { name: 'SAQUE HAKUSHIKA', unitType: 'UNIDADE' },
    { name: 'SUCO CONC ABACAXI', unitType: 'UNIDADE' },
    { name: 'SUCO CONC CRAMBERRY', unitType: 'UNIDADE' },
    { name: 'SUCO CONC MANGA', unitType: 'UNIDADE' },
    { name: 'SUCO CONC UVA', unitType: 'UNIDADE' },
    { name: 'TEQUILA OURO', unitType: 'UNIDADE' },
    { name: 'TEQUILA PRATA', unitType: 'UNIDADE' },
    { name: 'VERMUTE DRY', unitType: 'UNIDADE' },
    { name: 'VERMUTE ROSSO', unitType: 'UNIDADE' },
    { name: 'VERMUTE SECO', unitType: 'UNIDADE' },
    { name: 'VINHO DO PORTO', unitType: 'UNIDADE' },
    { name: 'VODKA RUSSIA', unitType: 'UNIDADE' },
    { name: 'WEBER HAUS', unitType: 'UNIDADE' },
    { name: 'WHISKY BLACK LABEL', unitType: 'UNIDADE' },
    { name: 'WHISKY BULLET BOURBON', unitType: 'UNIDADE' },
    { name: 'WHISKY DOUBLE BLACK', unitType: 'UNIDADE' },
    { name: 'WHISKY JACK DANIEL´S', unitType: 'UNIDADE' },
    { name: 'WHISKY JEAM BEAM', unitType: 'UNIDADE' },
    { name: 'WHISKY RED LABEL', unitType: 'UNIDADE' },
    { name: 'XAROPE CEREJA', unitType: 'UNIDADE' },
    { name: 'XAROPE DE ABACAXI', unitType: 'UNIDADE' },
    { name: 'XAROPE DE FRAMBOESA', unitType: 'UNIDADE' },
    { name: 'XAROPE DE GRENADINE', unitType: 'UNIDADE' },
    { name: 'XAROPE DE KIWI', unitType: 'UNIDADE' },
    { name: 'XAROPE DE LIMÃO SICILIANO', unitType: 'UNIDADE' },
    { name: 'XAROPE DE MAÇA', unitType: 'UNIDADE' },
    { name: 'XAROPE GENGIBRE', unitType: 'UNIDADE' },
    { name: 'XAROPE MARACÚJA', unitType: 'UNIDADE' },
    { name: 'XAROPE MORANGO', unitType: 'UNIDADE' },
    { name: 'ABACATE', unitType: 'KILO' },
    { name: 'ABACAXI', unitType: 'UNIDADE' },
    { name: 'ACELGA', unitType: 'UNIDADE' },
    { name: 'ALECRIM MAÇO', unitType: 'UNIDADE' },
    { name: 'ALFACE AMERICANA', unitType: 'UNIDADE' },
    { name: 'ALHO DESCASCADO', unitType: 'UNIDADE' },
    { name: 'ALHO PORO', unitType: 'UNIDADE' },
    { name: 'AMORA E FRAMBOESA CONG.', unitType: 'UNIDADE' },
    { name: 'ASPARGO', unitType: 'UNIDADE' },
    { name: 'BATATA INGLESA', unitType: 'KILO' },
    { name: 'BATATA DOCE ROXA', unitType: 'KILO' },
    { name: 'BERINGELA', unitType: 'KILO' },
    { name: 'BROCOLIS', unitType: 'KILO' },
    { name: 'CAJU', unitType: 'KILO' },
    { name: 'CEBOLA BRANCA', unitType: 'KILO' },
    { name: 'CEBOLA ROXA', unitType: 'KILO' },
    { name: 'CEBOLINHA', unitType: 'UNIDADE' },
    { name: 'CENOURA', unitType: 'KILO' },
    { name: 'CHUCHU', unitType: 'KILO' },
    { name: 'COUVE FLOR', unitType: 'KILO' },
    { name: 'FRAMBOESA FRUTA FRESCA', unitType: 'KILO' },
    { name: 'GENGIBRE', unitType: 'KILO' },
    { name: 'HORTELÃ', unitType: 'UNIDADE' },
    { name: 'KIWI', unitType: 'KILO' },
    { name: 'LARANJA KILO', unitType: 'KILO' },
    { name: 'LARANJA CAIXA', unitType: 'UNIDADE' },
    { name: 'LIMAO SICILIANO', unitType: 'KILO' },
    { name: 'LIMÃO TAITI KILO', unitType: 'KILO' },
    { name: 'LIMÃO TAITI CAIXA', unitType: 'UNIDADE' },
    { name: 'MAÇA VERDE', unitType: 'KILO' },
    { name: 'MANGA', unitType: 'KILO' },
    { name: 'MANJERICÃO', unitType: 'UNIDADE' },
    { name: 'MARACUJÁ', unitType: 'KILO' },
    { name: 'MELANCIA', unitType: 'KILO' },
    { name: 'MORANGO', unitType: 'UNIDADE' },
    { name: 'NIRA', unitType: 'UNIDADE' },
    { name: 'PASSERI', unitType: 'UNIDADE' },
    { name: 'PEPINO JAPONES(TORTO)', unitType: 'KILO' },
    { name: 'PIMENTA DEDO DE MOÇA', unitType: 'KILO' },
    { name: 'PIMENTA ROSA', unitType: 'KILO' },
    { name: 'PIMENTÃO VERMELHO', unitType: 'KILO' },
    { name: 'PITAYA ROSA', unitType: 'KILO' },
    { name: 'REPOLHO BRANCO', unitType: 'KILO' },
    { name: 'REPOLHO ROXO', unitType: 'KILO' },
    { name: 'RUCULA', unitType: 'UNIDADE' },
    { name: 'TANGERINA', unitType: 'KILO' },
    { name: 'TOMATE', unitType: 'KILO' },
    { name: 'TOMATE CEREJA', unitType: 'KILO' },
    { name: 'VAGEM', unitType: 'KILO' },
    { name: 'AMENDOS LAMINADAS', unitType: 'UNIDADE' },
    { name: 'MANTEIGA TRUFADA', unitType: 'UNIDADE' },
    { name: 'MEL DA SERRA SILVESTRE', unitType: 'UNIDADE' },
    { name: 'MEL TRUFADO', unitType: 'UNIDADE' },
    { name: 'NOZES QUEBRADA', unitType: 'UNIDADE' },
    { name: 'ATUM', unitType: 'KILO' },
    { name: 'POLVO', unitType: 'KILO' },
    { name: 'PREGO', unitType: 'KILO' },
    { name: 'BOTAO DE LULA', unitType: 'KILO' },
    { name: 'CAMARÃO LIMPO', unitType: 'KILO' },
    { name: 'CAMARÃO EMPANADO', unitType: 'KILO' },
    { name: 'OSTRA FRESCA', unitType: 'KILO' },
    { name: 'OSTRA GRATINADA', unitType: 'KILO' },
    { name: 'OVAS', unitType: 'KILO' },
    { name: 'CREEM CHESE POLENGUI', unitType: 'UNIDADE' },
    { name: 'CREEM CHESE POLENGUI CAIXA', unitType: 'UNIDADE' },
    { name: 'AÇUCAR SACHE', unitType: 'UNIDADE' },
    { name: 'ADOÇANTE SACHE', unitType: 'UNIDADE' },
    { name: 'AGUÁ SANITÁRIA', unitType: 'UNIDADE' },
    { name: 'ALCOOL 70', unitType: 'UNIDADE' },
    { name: 'ALCOOL GEL', unitType: 'UNIDADE' },
    { name: 'AVENTAL', unitType: 'UNIDADE' },
    { name: 'BOBINA PICOTADA GRANDE', unitType: 'UNIDADE' },
    { name: 'BOBINA PICOTADA PEQUENA', unitType: 'UNIDADE' },
    { name: 'BUCHA DE LAVAR LOUÇA', unitType: 'UNIDADE' },
    { name: 'BUCHA DE LIMPEZA PESADA', unitType: 'UNIDADE' },
    { name: 'CANUDO DE SUCO', unitType: 'UNIDADE' },
    { name: 'CANUDO DE SAQUERINHA', unitType: 'UNIDADE' },
    { name: 'DESINFETANTE', unitType: 'UNIDADE' },
    { name: 'DETERGENTE GALÃO', unitType: 'UNIDADE' },
    { name: 'EMB 4 DIV MO 100-4', unitType: 'UNIDADE' },
    { name: 'EMB CARPACCIO E TEMAKI HM6', unitType: 'UNIDADE' },
    { name: 'EMB SEM DIV MO100', unitType: 'UNIDADE' },
    { name: 'GUARDANAPO 20/21', unitType: 'UNIDADE' },
    { name: 'GUARDANAPO 30/30', unitType: 'UNIDADE' },
    { name: 'LIMPA ALUMINIO', unitType: 'UNIDADE' },
    { name: 'LUVA AMARELA', unitType: 'UNIDADE' },
    { name: 'LUVA LATEX', unitType: 'UNIDADE' },
    { name: 'LUVA NITRILICA', unitType: 'UNIDADE' },
    { name: 'PALITO DE DENTE', unitType: 'UNIDADE' },
    { name: 'PANO BRANCO', unitType: 'UNIDADE' },
    { name: 'PANO XADREZ', unitType: 'UNIDADE' },
    { name: 'PAPEL HIGIENICO INTERFOLHADO', unitType: 'UNIDADE' },
    { name: 'PAPEL TOALHA INTERFOLHADO', unitType: 'UNIDADE' },
    { name: 'PERFLEX', unitType: 'UNIDADE' },
    { name: 'POTE PORCIONA 500ML /1L /1,5L', unitType: 'UNIDADE' },
    { name: 'PVC', unitType: 'UNIDADE' },
    { name: 'SABONETE LIQUIDO', unitType: 'UNIDADE' },
    { name: 'SACO DE LIXO 50/ 100/ 200 LITROS', unitType: 'UNIDADE' },
    { name: 'SACOLA E SACO KRAFT', unitType: 'UNIDADE' },
    { name: 'SAL SACHE', unitType: 'UNIDADE' },
    { name: 'SAQUINHO PARA TALHER', unitType: 'UNIDADE' },
    { name: 'TOUCA DESCARTAVEL', unitType: 'UNIDADE' },
    { name: 'TOUCA PRETA', unitType: 'UNIDADE' },
    { name: 'VELINHA ANIVERSÁRIO', unitType: 'UNIDADE' },
    { name: 'SAQUINHO ABSORVENTE', unitType: 'UNIDADE' },
    { name: 'AJINOMOTO (GLUTAMATO)', unitType: 'UNIDADE' },
    { name: 'ARR GRÃO CURTO REI ARTUM', unitType: 'UNIDADE' },
    { name: 'EDAMAME', unitType: 'KILO' },
    { name: 'FARINHA TEMPURÁ', unitType: 'UNIDADE' },
    { name: 'FARINHA PANKO 1KG', unitType: 'UNIDADE' },
    { name: 'HONDASHI', unitType: 'UNIDADE' },
    { name: 'KAMABOKO/NARUTO', unitType: 'UNIDADE' },
    { name: 'KANI', unitType: 'UNIDADE' },
    { name: 'ALGA', unitType: 'UNIDADE' },
    { name: 'GUIOZA PRONTA', unitType: 'UNIDADE' },
    { name: 'MISSO BALDE 10KG', unitType: 'UNIDADE' },
    { name: 'MASSA HARUMAKI', unitType: 'UNIDADE' },
    { name: 'AZUMA MIRIM', unitType: 'UNIDADE' },
    { name: 'SHIMEJI', unitType: 'KILO' },
    { name: 'SHITAKE', unitType: 'KILO' },
    { name: 'OLÉO DE GERGELIM', unitType: 'UNIDADE' },
    { name: 'TOFU', unitType: 'KILO' },
    { name: 'TEMPERO SUSHI', unitType: 'UNIDADE' },
    { name: 'SHOYO TRADICIONAL GALÃO', unitType: 'UNIDADE' },
    { name: 'SHOYO LIGHT GALÃO', unitType: 'UNIDADE' },
    { name: 'SHOYO LIGHT SACHÊ', unitType: 'UNIDADE' },
    { name: 'SHOYO TRAD SACHÊ', unitType: 'UNIDADE' },
    { name: 'TARÊ SACHÊ', unitType: 'UNIDADE' },
    { name: 'ADAPTADOR HASHI', unitType: 'UNIDADE' },
    { name: 'MACARRÃO YAKISSOBA', unitType: 'UNIDADE' },
    { name: 'MACARRÃO UDOM', unitType: 'UNIDADE' },
    { name: 'MACARRÃO LAMEM', unitType: 'UNIDADE' },
    { name: 'GENGIBRE EM CONSERVA', unitType: 'UNIDADE' },
    { name: 'GERGELIM BRANCO', unitType: 'UNIDADE' },
    { name: 'GERGELIM PRETO', unitType: 'UNIDADE' },
    { name: 'WASSABI', unitType: 'UNIDADE' },
    { name: 'MOLHO KIKKOMAM', unitType: 'UNIDADE' },
    { name: 'MOLHO SWITH CHILLI', unitType: 'UNIDADE' },
    { name: 'BANCHA', unitType: 'UNIDADE' }
  ]

  constructor(
    protected override purchaseItemsService: PurchaseItemsService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService,
    protected override router: Router,
  ) {
    super(purchaseItemsService, toastr, modalService, cacheService, confirmationModal, router, deviceService);
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
    const g = UtilsService.groupByKey('category', this.lista);
    let script: string = 'db.purchaseitems.insertMany([';

    this.purchaseItemsService.find().then((response: any[]) => {
      const items = response;

      response.forEach((r) => {
        const founded = this.lista.find(y => y.name === r.name);

        if (founded) {
          script = script += `{ tenant: new ObjectId('64c85a21d49febb4dd04195b'), name: '${founded.name}', _id: new ObjectId('${r._id}'), unitType: '${founded.unitType}' },`
        }
      });

      script = script.slice(0, -1);
      script += ']);'
      console.log(script);
    })
    // g.forEach((ff, idx1) => {
    //   ff.items.forEach((ee: any, idx2: number) => {
    //     g[idx1].items[idx2] = this.itemss.find(x => x._id === ee.item);
    //   });
    // });

    // let script = 'db.purchasecategoryitems.insertMany([';

    // g.forEach((ff, idx1) => {
    //   script = script += `{ tenant: new ObjectId('64c85a21d49febb4dd04195b'), category: new ObjectId('${ff.category}'), items: [`
    //   ff.items.forEach((ee: any, idx2: number) => {
    //     script = script += `{ _id: new ObjectId('${ee._id}'), name: '${ee.name}' },`
    //     g[idx1].items[idx2] = this.itemss.find(x => x._id === ee.item);
    //   });

    //   script = script.slice(0, -1);
    //   script = script += ']},'
    // });
    // script = script.slice(0, -1);
    // script = script += '])'
    // console.log(script);
  }

  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }


  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: PurchaseItemsModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.purchaseItemsService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural1 = itemsCount > 1 ? 'ns' : 'm';
      const plural2 = itemsCount > 1 ? 's' : '';

      if (count === itemsCount) {
        this.getData();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Ite${plural1} excluido${plural2} com sucesso`);
        }
      }
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });
  }

  public getData(): void {
    this.cacheService.purchaseItems(true)
      .then((response) => {
        this.data = response?.sort((a, b) => a.name.localeCompare(b.name));
        this.auxData = this.data;
      });
  }

  public addNew(): void {
    this.openView(new PurchaseItemsModel());
  }

  public selectedItem(item: PurchaseItemsModel) {
    this.selectedItems = [item];
  }

  public openView(data: PurchaseItemsModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
        }
      });
  }

  public filter() {
    const keysFilter = ['name'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data = this.auxData;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data = ocurrences;
    } else {
      this.data = this.auxData;
    }
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }
}

import { ProfileModel } from "./profile.model"
import { TenantModel } from "./tenant.model"

export class MyProfileModel {
    public email!: string
    public username!: string
    public full_name!: string
    public phone_number!: string
    public picture!: string
    public enabled!: boolean
    public google_login!: boolean
    public _id!: string
    public company!: any;
    public profile!: ProfileModel;
    public tenant!: TenantModel;
  }
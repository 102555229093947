import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { MyProfileModel } from "src/app/shared/models/admin/my-profile.model";
import { LocalStorageHelper } from "src/app/shared/utils/local-storage";

export abstract class BaseComponent {

    constructor(
        protected deviceService: DeviceDetectorService,
        protected router: Router) {

    }

    changeTextToUppercase(form: FormGroup, field: string[]): void {
        field.forEach(f => {
            const obj: any = {};
            obj[f] = form.controls[f].value.toUpperCase();
            form.patchValue(obj);
        });
    }


    hasPermission(permission: string): boolean {
        const roles = LocalStorageHelper.getItem<MyProfileModel>('USER_INFO')?.profile?.roles;
        return !!roles.find(x => x.name === permission);
    }

    checkRoute(permission: string): void {
        if (!this.hasPermission(permission)) {
            LocalStorageHelper.removeItem('TOKEN');
            LocalStorageHelper.removeItem('USER_INFO');
            this.router.navigate(['/login']);
        }
    }

    isMobile(): boolean {
        return this.deviceService.isMobile();
    }
}
import { Injectable } from "@angular/core";


@Injectable()
export class LocalStorageHelper {

  constructor() {
  }

  public static setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static getItem<T>(key: string): T {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static clear(): void {
    localStorage.clear();
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unlogged',
  templateUrl: './unlogged.component.html',
})
export class UnLoggedComponent implements OnInit {

  public classCss = 'sidebar-collapse sidebar-closed';

  constructor(
  ) {

  }

  ngOnInit(): void {

  }



}


import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SupplierModel } from "../shared/models/purchase/supplier.model";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class SupplierService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/supplier';

  public async find(): Promise<SupplierModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(profile: SupplierModel): Promise<any> {
    return this.post({
      path: this.path,
      request: profile
    });
  }

  public async update(profile: SupplierModel): Promise<any> {
    return this.put({
      path: `${this.path}/${profile._id}`,
      request: profile
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
    });
  }
}
<body class="hold-transition login-page">
    <div [formGroup]="formGroupName" class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center"> <a class="h1">
                    <img src="../../assets/logo/fleXX-logos_black_text.png" alt="fleXX"
                        style="max-height:none; width: 100px;">
                </a> </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <p class="login-box-msg" style="font-size: 20px;"><b>Login</b></p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p class="login-box-msg">Faça login para iniciar sua sessão</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="field_container_error" [ngClass]="{'field_error': isValid('email')}"> <input
                                    type="email" formControlName="email" class="form-control" placeholder="e-mail">
                                <div class="input-group-append">
                                    <div class="input-group-text"> <span class="fas fa-envelope"></span> </div>
                                </div>
                            </div>
                            <div *ngIf="isValid('email')" id="exampleInputEmail1-error" class="error invalid-feedback">
                                Campo
                                obrigatório</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="field_container_error" [ngClass]="{'field_error': isValid('password')}"> <input
                                    type="password" formControlName="password" class="form-control" placeholder="senha">
                                <div class="input-group-append">
                                    <div class="input-group-text"> <span class="fas fa-lock"></span> </div>
                                </div>
                            </div>
                            <div *ngIf="isValid('password')" id="exampleInputEmail1-error"
                                class="error invalid-feedback">Campo
                                obrigatório</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="social-auth-links text-center mt-2 mb-3">
                            <a class="btn btn-block btn-primary" (click)="signIn()" style="margin-bottom: 15px;"> Entrar
                            </a>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12">
                        <p class="mb-1" style="font-size: small;"> <a [routerLink]="['/forget-password']">Esqueci minha
                                senha</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
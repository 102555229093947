import { Component, OnInit } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { UserModel } from 'src/app/shared/models/admin/user.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { UserBaseComponent } from '../user-base.component';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-user',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent extends UserBaseComponent implements OnInit {

  selectAllChecked: boolean = false;
  selectedRowIndex!: number;
  selectedItems: any[] = [];
  search!: string;

  constructor(
    private userService: UserService,
    protected override toastr: Toastr,
    protected override router: Router,
    private cacheService: CacheService,
    protected override confirmationModal: ConfirmationModalService,
    protected override deviceService: DeviceDetectorService) {

    super(toastr, router, deviceService, confirmationModal);
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  public getData(): void {
    this.cacheService.companies().then((companies) => {
      this.companies = companies;
      this.cacheService.profiles().then((profiles) => {
        this.profiles = profiles;
        this.getUsers();
      });
    });
  }

  getUsers(): void {
    this.userService.find()
      .then((response: UserModel[]) => {

        response.forEach((r, i) => {
          const aux: any[] = [];
          r.company.forEach((c) => {
            aux.push(this.companies.find((cs) => cs._id.toString() === c._id.toString()));
          });
          response[i].companies_names = aux?.map(x => x?.name).join(',');
          response[i].profile_name = response[i].profile.name;
        });
        this.data = response;
        this.auxData = this.data;
      });
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });
  }

  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }

  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: UserModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.userService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural = itemsCount > 1 ? 's' : '';

      if (count === itemsCount) {
        this.getUsers();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Usuário${plural} excluido${plural} com sucesso`);
        }
      }
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  public addNew(): void {
    this.openView(new UserModel());
  }

  public selectedItem(item: UserModel) {
    this.selectedItems = [item];
  }

  async openView(data: UserModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
          companies: this.companies,
          profiles: this.profiles
        }
      });
  }

  public filter() {

    const keysFilter = ['email', 'username', 'full_name', 'phone_number', 'companies_names', 'profile_name'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data = this.auxData;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data = ocurrences;
    } else {
      this.data = this.auxData;
    }
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }
}

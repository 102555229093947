<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Meu perfil</li>
                </ol>
            </div>
        </div>
    </div>
</section>


<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Minhas informações</h3>
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal" [formGroup]="formMyProfile">
                            <div class="form-group row">
                                <label for="inputName" class="col-sm-2 col-form-label">Nome</label>
                                <div class="col-sm-10">
                                    <input type="email" formControlName="full_name" class="form-control" id="inputName"
                                        placeholder="Name" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" formControlName="email" class="form-control" id="inputEmail"
                                        placeholder="Email" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="inputExperience" class="col-sm-2 col-form-label">Telefone</label>
                                <div class="col-sm-10">
                                    <input type="text" formControlName="phone_number" class="form-control"
                                        id="inputName2" placeholder="Name" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputName2" class="col-sm-2 col-form-label">Empresas</label>
                                <div class="col-sm-10">
                                    <input type="text" formControlName="company" class="form-control" id="inputName2"
                                        placeholder="Name" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="inputName2" class="col-sm-2 col-form-label">Perfil</label>
                                <div class="col-sm-10">
                                    <input type="text" formControlName="profile" class="form-control" id="inputName2"
                                        placeholder="Name" />
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="!isMobile()">
                                <div class="offset-sm-2 col-sm-4">
                                    <button type="submit" *ngIf="!myProfile.google_login" class="btn btn-success"
                                        (click)="openChangePasswordModal()">Alterar a senha</button>
                                </div>

                                <div class="offset-sm-2 col-sm-4" style="text-align: right;">
                                    <button type="submit" class="btn btn-primary">Salvar</button>
                                </div>
                            </div>

                            <div class="form-group row" *ngIf="isMobile()">
                                <button type="submit" *ngIf="!myProfile.google_login"
                                    class="btn btn-success btn-block btn-lg" (click)="openChangePasswordModal()">Alterar
                                    a senha</button>
                                <button type="submit" class="btn btn-primary btn-block btn-lg"><i
                                        class="fa fa-save"></i> Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<modal [id]="'modal-my-profile'" size="lg" (onConfirm)="changePassword()" [showRemoveButton]="false"
    [disableConfirmButton]="formChangePassword.status != 'VALID'" (onCancel)="clearForm()" title="Alterar senha"
    confirmButtonText="Confirmar">
    <form [formGroup]="formChangePassword">
        <div class="input-group mb-3">
            <input type="password" formControlName="password" class="form-control" placeholder="Senha" />
            <div class="input-group-append">
                <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                </div>
            </div>
            <span *ngIf="passwordControl?.invalid && (passwordControl?.dirty || passwordControl?.touched)"
                class="error invalid-feedback">A senha precisa ter pelo menos 8 caracteres uma letra maíuscula e um
                número.</span>
        </div>
        <div class="input-group mb-3">
            <input type="password" formControlName="confirmPassword" class="form-control"
                placeholder="Confirme a senha" />
            <div class="input-group-append">
                <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                </div>
            </div>
            <span
                *ngIf="confirmPasswordControl?.invalid && (confirmPasswordControl?.dirty || confirmPasswordControl?.touched)"
                class="error invalid-feedback">As senhas não conferem</span>
        </div>
    </form>
</modal>
<div class="modal fade" aria-hidden="true" data-backdrop="false" id="{{id}}">
    <div class="modal-dialog modal-{{size}}">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{title}}</h4>
          <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <ng-content></ng-content>
        </div>
        <div class="modal-footer">
          <div class="align-self-start mr-auto" *ngIf="showRemoveButton">
            <button type="button" class="btn btn-danger text-left" (click)="remove()">{{deleteButtonText}}</button>
          </div>
    
          <div class="align-self-end ml-auto">
            <button type="button" class="btn btn-default text-right" style="margin-right: 10px;" data-dismiss="modal" (click)="cancel()">{{cancelButtonText}}</button>          
            <button *ngIf="showConfirmButton" type="button" class="btn btn-primary" [disabled]="disableConfirmButton" (click)="confirm()">{{confirmButtonText}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PurchaseCategoryItemsModel } from "../shared/models/purchase/purchase-category-items.model";
import { HttpRequestBaseService } from "./http-request-base.service";
import { BackgroundRunningService } from "./background-running.service";

@Injectable()
export class PurchaseCategoryItemsService extends HttpRequestBaseService<any, any> {
  constructor(
    protected override http: HttpClient,
    private backgroundRunningService: BackgroundRunningService) {
    super(http);
  }

  path: string = '/v1/purchase-category-items';

  public async find(): Promise<PurchaseCategoryItemsModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(purchaseCategoryItems: PurchaseCategoryItemsModel): Promise<any> {
    return this.post({
      path: this.path,
      request: purchaseCategoryItems,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async update(purchaseCategoryItems: PurchaseCategoryItemsModel): Promise<any> {
    return this.put({
      path: `${this.path}/${purchaseCategoryItems._id}`,
      request: purchaseCategoryItems,
      func: () => this.backgroundRunningService.updateStock()
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
      func: () => this.backgroundRunningService.updateStock()
    });
  }
}
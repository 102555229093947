import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { QuantityStockItemsModel } from "../shared/models/purchase/quantity-stock-items.model";
import { BackgroundRunningService } from "./background-running.service";
import { HttpRequestBaseService } from "./http-request-base.service";
import { QuantityStockModel } from "../shared/models/purchase/quantity-stock.model";

@Injectable()
export class QuantityStockItemsService extends HttpRequestBaseService<any, any> {
  constructor(
    protected override http: HttpClient,
    private backgroundRunningService: BackgroundRunningService) {
    super(http);
  }

  path: string = '/v1/quantity-stock-items';

  public async find(): Promise<QuantityStockItemsModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(): Promise<any> {
    return this.post({
      path: this.path,
      request: {},
      skip: true
    });
  }

  public async update(_id: string, quantityStock: QuantityStockModel): Promise<any> {
    return this.put({
      path: `${this.path}/${_id}`,
      request: quantityStock,
      skip: true,
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
    });
  }
}
import { Injectable } from "@angular/core";
import { CurrentStockQuantityService } from "./current-stock-quantity.service";
import { QuantityStockItemsService } from "./quantity-stock-items.service";

@Injectable({ providedIn: 'root' })
export class BackgroundRunningService {

    constructor(
        private currentStockQuantityService: CurrentStockQuantityService,
        private quantityStockItemsService: QuantityStockItemsService) {

    }


    public updateStock(): Promise<void> {

        return new Promise<void>((resolve, reject) => {
            this.currentStockQuantityService.add()
                .then(() => {
                    this.quantityStockItemsService.add()
                        .then(() => {
                            resolve();
                        }, () => { console.log('quantityStock error'); reject(); });
                }, () => { console.log('currentStock error'); reject() });
        });
    }
}
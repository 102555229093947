import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NetworkConnection } from 'src/app/services/network-connection.service';

@Component({
  selector: 'app-lost-connection',
  templateUrl: './lost-connection.component.html',
  styleUrls: ['./lost-connection.component.scss']
})
export class LostConnectionComponent {


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Toastr } from 'src/app/services/toastr.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { ProfileBaseComponent } from '../profile-base.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent extends ProfileBaseComponent implements OnInit {

  selectedItems: any[] = [];
  state: any;

  constructor(
    protected override profileService: ProfileService,
    protected override toastr: Toastr,
    protected override router: Router,
    protected override cacheService: CacheService,
    protected override confirmationModal: ConfirmationModalService,
    protected override deviceService: DeviceDetectorService
  ) {

    super(confirmationModal, profileService, toastr, cacheService, router, deviceService);

    this.cacheService.roles().then(roles => {
      this.rolesGrouped = this.groupByKey('description', roles);
    });

    this.state = this.router.getCurrentNavigation()?.extras?.state;

    if (!this.state) {
      this.router.navigate([this.module.urlList]);
    }
  }

  ngOnInit(): void {
    const data = (this.state?.['data'] as any);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.checkRoute(data._id ? this.module.permission.UPDATE : this.module.permission.ADD);

    this.createForm(data);
  }

  public onRemove(): void {
    this.selectedItems = [this.itemOpenedToEdit];
    this.confirmationModal.open();
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.profileService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  public onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.profileService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }
}

import { Component, OnInit, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageHelper } from 'src/app/shared/utils/local-storage';
import { Toastr } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { TokenModel } from 'src/app/shared/models/common/token.model';
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { UserInfoService } from 'src/app/services/user-info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public static readonly SESSION_STORAGE_KEY: string = "accessToken";
  public formGroupName!: FormGroup;

  user!: SocialUser;
  loggedIn!: boolean;
  version!: string;

  public loginError = { hasError: false, message: '' };

  constructor(@Optional() private controlContainer: ControlContainer,
    private toastr: Toastr,
    private authService: AuthService,
    private userInfoService: UserInfoService,
    private router: Router,
  ) {
    this.version = environment.appVersion;
    this.formGroupName = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    const token = LocalStorageHelper.getItem<TokenModel>('TOKEN');
    if (token && token.access_token) {
      this.router.navigate(['dashboard']);
    }
  }

  signIn(): void {
    this.authService.login(this.form.email.value, this.form.password.value)
      .then((response) => {
        LocalStorageHelper.setItem('TOKEN', response);
        this.userInfoService.getUserInfo().then((response) => {
          LocalStorageHelper.setItem('USER_INFO', response);
          this.router.navigate(['dashboard'], {
            replaceUrl: true,
          });
        });
      }, (response: HttpErrorResponse) => {
        this.toastr.error(response.error.errors);
      });
  }

  async handleCredentialResponse(socialUser: SocialUser) {
    if (socialUser.idToken) {
      this.form.email.setValue(null);
      this.form.password.setValue(null);
    }

    this.authService.login(this.form.email.value, this.form.password.value)
      .then((response) => {
        LocalStorageHelper.setItem('TOKEN', response);

        this.userInfoService.getUserInfo().then((response) => {
          LocalStorageHelper.setItem('USER_INFO', response);

          this.router.navigate(['dashboard'], {
            replaceUrl: true,
          });
        });

      }, (response: HttpErrorResponse) => {
        this.toastr.error(response.error.errors);
      });
  }

  get form(): any {
    return this.formGroupName['controls'];
  }

  isValid(inputname: string) {

    return this.formGroupName.controls[inputname].invalid &&
      (this.formGroupName.controls[inputname].dirty || this.formGroupName.controls[inputname].touched)
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/account/login/login.component';
import { LoggedComponent } from './shared/layout/logged/logged.component';
import { VerifyEmailComponent } from './pages/account/verify-email/verify-email.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/account/forget-password/forget-password.component';
import { UnLoggedComponent } from './shared/layout/unlogged/unlogged.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: LoggedComponent,
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'admin/user',
    component: LoggedComponent,
    loadChildren: () => import('./pages/admin/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'admin/company',
    component: LoggedComponent,
    loadChildren: () => import('./pages/admin/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'admin/profile',
    component: LoggedComponent,
    loadChildren: () => import('./pages/admin/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'purchase/category',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/purchase-category/purchase-category.module').then((m) => m.PurchaseCategoryModule),
  },
  {
    path: 'purchase/supplier',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/supplier/supplier.module').then((m) => m.SupplierModule),
  },
  {
    path: 'purchase/items',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/purchase-items/purchase-items.module').then((m) => m.PurchaseItemsModule),
  },
  {
    path: 'purchase/category-items',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/purchase-category-items/purchase-category-items.module').then((m) => m.PurchaseCategoryItemsModule),
  },
  {
    path: 'purchase/quantity-stock-items',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/quantity-stock-items/quantity-stock-items.module').then((m) => m.QuantityStockItemsModule),
  },
  {
    path: 'purchase/current-stock-quantity',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/current-stock-quantity/current-stock-quantity.module').then((m) => m.CurrentStockQuantityModule),
  },
  {
    path: 'purchase/quotation',
    component: UnLoggedComponent,
    loadChildren: () => import('./pages/purchase/quotation/quotation.module').then((m) => m.QuotationModule),
  },
  {
    path: 'purchase/quotation/all',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/quotation/quotation.module').then((m) => m.QuotationModule),
  },
  {
    path: 'purchase/orders',
    component: LoggedComponent,
    loadChildren: () => import('./pages/purchase/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'verify-email/:id',
    component: VerifyEmailComponent
  },
  {
    path: 'change-password/:id',
    component: ChangePasswordComponent
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent
  },
  {
    path: 'my-profile',
    component: LoggedComponent,
    loadChildren: () => import('./pages/account/my-profile/my-profile.module').then((m) => m.MyProfileModule),
  },
  {
    path: 'error',
    component: LoggedComponent,
    loadChildren: () => import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

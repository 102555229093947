<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{module.parentName}}</a></li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h2 class="card-title card-title-center">{{module.name}}</h2>
                </div>
                <div class="col-6">
                    <button
                        [style.visibility]="hasPermission(module.permission.DELETE) && form['_id'].value ? 'visible' : 'hidden'"
                        [disabled]="" type="button" class="btn btn-danger btn-sm btn-card-action" title="Excluir"
                        (click)="remove()">
                        <i class="fas fa-trash-alt fa-sm"></i> excluir
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form #formGroup2 [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="name">Nome</label>
                                    <input type="text" #name class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['name'].invalid && (form['name'].dirty || form['name'].touched) }"
                                        formControlName="name" />
                                    <span *ngIf="form['name'].invalid && (form['name'].dirty || form['name'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="description">Descrição</label>
                                    <input type="text" #description class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['description'].invalid && (form['description'].dirty || form['description'].touched) }"
                                        formControlName="description" />
                                    <span
                                        *ngIf="form['description'].invalid && (form['description'].dirty || form['description'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <fieldset class="fieldset" style="margin-bottom: 1.3rem;"
                                    [ngClass]="{'fieldset-error': form['roles'].errors?.['error'] }">
                                    <legend>Permissões</legend>

                                    <div class="row">
                                        <div class="col-sm-6 col-md-4" *ngFor="let role of rolesGrouped; let i = index">
                                            <div class="icheck-primary">
                                                <input type="checkbox" [checked]="role.checked"
                                                    id="checkboxAllPermission{{i}}"
                                                    (click)="chechAllPermissions($event, role, i)" />
                                                <label for="checkboxAllPermission{{i}}" style="font-weight: bold;">
                                                    {{role.description}}
                                                </label>
                                            </div>
                                            <div class="form-group clearfix" style="margin-left: 20px;"
                                                *ngFor="let item of role.items; let j = index">
                                                <div class="icheck-primary">
                                                    <input type="checkbox" [checked]="item.checked"
                                                        id="checkboxPermission{{i}}{{j}}"
                                                        (click)="roleSelected(i, j, $event, role.description)" />
                                                    <label for="checkboxPermission{{i}}{{j}}" style="font-weight: 200;">
                                                        {{getMethodDescription(item.method)}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </fieldset>
                                <span *ngIf="form['roles']?.errors?.['error']" class="error invalid-feedback">Selecione
                                    ao menos
                                    uma
                                    permissão</span>
                            </div>
                        </div>
                        <div class="row action-save-back" *ngIf="isMobile()">
                            <div class="col-6">
                                <button type="button" class="btn btn-default text- btn-sm pull-left"
                                    [routerLink]="[module.urlList]"><i class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    *ngIf="hasPermission(module.permission.UPDATE) && form['_id'].value || hasPermission(module.permission.ADD) && !form['_id'].value"
                                    class="btn btn-primary btn-sm btn-card-action">
                                    <i class="fa fa-save"></i> salvar
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isMobile()" style="margin-top: 10px;">
                            <div class="col-6 align-self-end ml-auto">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    class="btn btn-primary btn-sm pull-right"><i class="fa fa-save"></i>
                                    salvar</button>

                                <button type="button" class="btn btn-default btn-sm pull-right"
                                    style="margin-right: 10px;" [routerLink]="[module.urlList]"><i
                                        class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?">
</confirmation-modal>
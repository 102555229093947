<body class="hold-transition login-page">
    <div [formGroup]="formGroup" class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center"> <a class="h1">
                    <img src="../../assets/logo/fleXX-logos_black_text.png" alt="fleXX"
                        style="max-height:none; width: 100px;">
                </a> </div>
            <div class="card-body">
                <p class="login-box-msg">Informe seu endereço de e-mail, para receber um link de recuperação
                    da sua senha</p>

                <div class="input-group mb-3">

                    <input type="email"
                        [ngClass]="{'is-invalid': form['email'].invalid && (form['email'].dirty || form['email'].touched) }"
                        formControlName="email" class="form-control" placeholder="e-mail">
                    <div class="input-group-append">
                        <div class="input-group-text"> <span class="fas fa-envelope"></span> </div>
                    </div>
                    <div *ngIf="isValid('email')" id="exampleInputEmail1-error" class="error invalid-feedback">Campo
                        obrigatório</div>
                </div>
                <div class="social-auth-links text-center mt-2 mb-3">
                    <a class="btn btn-block btn-primary" (click)="send()" style="margin-bottom: 15px;"> Enviar </a>
                </div>
                <p class="mb-1" style="font-size: small;"> <a [routerLink]="['/login']">Login</a> </p>
            </div>
        </div>
    </div>
</body>
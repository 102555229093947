import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './pages/account/login/login.module';
import { LoggedModule } from './shared/layout/logged/logged.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './core/interceptors/interceptor';
import { UserModule } from './pages/admin/user/user.module';
import { ProfileModule } from './pages/admin/profile/profile.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from './shared/components/modal/modal.module';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { VerifyEmailComponent } from './pages/account/verify-email/verify-email.component';
import { ForgetPasswordComponent } from './pages/account/forget-password/forget-password.component';
import { MyProfileModule } from './pages/account/my-profile/my-profile.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SupplierModule } from './pages/purchase/supplier/supplier.module';
import { PurchaseItemsModule } from './pages/purchase/purchase-items/purchase-items.module';
import { PurchaseCategoryItemsModule } from './pages/purchase/purchase-category-items/purchase-category-items.module';
import { QuantityStockItemsModule } from './pages/purchase/quantity-stock-items/quantity-stock-items.module';
import { PurchaseCategoryModule } from './pages/purchase/purchase-category/purchase-category.module';
import { UnLoggedModule } from './shared/layout/unlogged/unlogged.module';
import { QuotationModule } from './pages/purchase/quotation/quotation.module';
import { ErrorsModule } from './pages/errors/errors.module';
import { StickyDirective, StickyDirectiveModule } from './shared/directives/sticky.directive';

const routes: any[] = [];

@NgModule({
  declarations: [AppComponent, LoaderComponent, VerifyEmailComponent, ChangePasswordComponent, ForgetPasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    UserModule,
    ProfileModule,
    SupplierModule,
    PurchaseCategoryModule,
    PurchaseItemsModule,
    PurchaseCategoryItemsModule,
    QuantityStockItemsModule,
    MyProfileModule,
    QuotationModule,
    LoginModule,
    FormsModule,
    LoggedModule,
    UnLoggedModule,
    ModalModule,
    ReactiveFormsModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    CommonModule,
    ErrorsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxMaskDirective, NgxMaskPipe,
    StickyDirectiveModule,
    RouterModule.forRoot(routes, { useHash: false })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true,
  },
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(environment.clientId)
        }
      ],
      onError: (err) => {
        console.log(err);
      }
    } as SocialAuthServiceConfig
  },
  provideNgxMask()],
  exports: [RouterModule, CommonModule, FormsModule, GoogleSigninButtonModule, BrowserModule, ReactiveFormsModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

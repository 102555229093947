import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, booleanAttribute } from "@angular/core";
import { Observable } from "rxjs";
import { LoaderService } from "src/app/shared/components/loader/loader.service";
import { TokenModel } from "src/app/shared/models/common/token.model";
import { LocalStorageHelper } from "src/app/shared/utils/local-storage";
import { finalize } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: LoaderService) {

  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const showLoading = req.headers.get('skip') !== 'true';

    if (showLoading) {
      this.totalRequests++;
      this.loadingService.setLoading(true);
    }

    const token = LocalStorageHelper.getItem<TokenModel>('TOKEN');

    if (token && req.method !== 'OPTIONS') {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.access_token}`
        },
      });
    }

    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Accept": '*'
      },
    });

    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests--;

        setTimeout(() => {
          if (this.totalRequests <= 0) {
            this.totalRequests = 0;
            this.loadingService.setLoading(false);

          }
          setTimeout(() => {
            if (this.totalRequests === 0) {
              this.loadingService.setLoading(false);
            }
          }, 4000);
        }, 50);
      })
    );
  }
}

import { Component } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CacheService } from "src/app/services/cache.service";
import { ProfileService } from "src/app/services/profile.service";
import { Toastr } from "src/app/services/toastr.service";
import { ConfirmationModalService } from "src/app/shared/components/confirmation-modal/confirmation-modal.service";
import { ProfileModel } from "src/app/shared/models/admin/profile.model";
import { BaseComponent } from "../../base/base.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { Module } from "src/app/shared/models/common/module.interface";
import { RolesGroupedModel } from "src/app/shared/models/admin/role-grouped.model";

@Component({
  template: ''
})
export abstract class ProfileBaseComponent extends BaseComponent {

  rolesGrouped: RolesGroupedModel[] = [];
  formGroup!: FormGroup;
  itemOpenedToEdit!: ProfileModel;
  data: ProfileModel[] = [];
  auxData: ProfileModel[] = [];

  public module: Module = {
    name: 'Perfil',
    pluralName: 'Perfis',
    urlList: '/admin/profile/list',
    urlView: '/admin/profile/view',
    parentName: 'Admin',
    permission: {
      GET: 'ADMIN_PROFILE_GET',
      ADD: 'ADMIN_PROFILE_ADD',
      UPDATE: 'ADMIN_PROFILE_UPDATE',
      DELETE: 'ADMIN_PROFILE_DELETE'
    }
  }

  constructor(
    protected confirmationModal: ConfirmationModalService,
    protected profileService: ProfileService,
    protected toastr: Toastr,
    protected cacheService: CacheService,
    protected override router: Router,
    protected override deviceService: DeviceDetectorService) {
    super(deviceService, router);
  }

  createForm(profile: ProfileModel = new ProfileModel()) {

    this.itemOpenedToEdit = profile;
    this.formGroup = new FormGroup({
      _id: new FormControl(profile._id),
      name: new FormControl(profile.name, Validators.required),
      description: new FormControl(profile.description, Validators.required),
      roles: new FormControl(this.rolesGrouped, this.atLestOneChecked())
    });

    this.rolesGrouped.forEach((role) => {
      role.checked = false;
      role.items.forEach((item) => {
        item.checked = false;
      });
    });

    if (profile._id) {
      this.rolesGrouped.forEach((role) => {

        role.items.forEach((item: any) => {
          if (profile?.roles?.some(x => x._id === item._id)) {
            item.checked = true;
          }
        });

        role.checked = role.items.filter((x: any) => x.checked).length === role.items.length;
      });
    }

    this.formGroup.statusChanges.subscribe((status) => {
      if (status === 'VALID' && !this.rolesGrouped.map(role => role.items).flat().some(x => x.checked)) {
        this.formGroup.setErrors({ error: true });
      }
    });
  }

  atLestOneChecked(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const atLestOneChecked = control.value?.map((x: any) => x.items).flat().some((x: any) => x.checked);

      if (atLestOneChecked) {
        return null;
      } else {
        if (!this.formGroup || !this.formGroup?.dirty) {
          return null;
        }
        return { error: true };
      }
    }
  }

  trackBy(x: any) {
    return x;
  }

  public setItem($event: any, idx: number) {
    if (this.formGroup.controls['roles'].value) {
      this.formGroup.controls['roles'].value[idx].checked = $event.target.checked;
      this.formGroup.controls['roles'].setValue(this.formGroup.controls['roles'].value);
    } else {

    }
  }

  chechAllPermissions($event: any, role: any, roleIdx: number): void {
    this.rolesGrouped[roleIdx].checked = $event.target.checked;

    this.rolesGrouped[roleIdx].items.forEach((item: any) => {
      item.checked = $event.target.checked;
    });

    this.formGroup.controls['roles'].setValue(Object.assign([], this.rolesGrouped));
    this.formGroup.markAsDirty();
  }

  allModuleChecked(description: string): boolean {
    const allRoles = this.rolesGrouped.find(x => x.description === description);
    let alreadyRoles = this.rolesGrouped.map(role => role.items).flat().filter(x => x.checked);

    alreadyRoles = alreadyRoles.filter(x => allRoles?.items.map((y: { _id: any; }) => y._id).includes(x._id));

    return (alreadyRoles.length === allRoles?.items.length);
  }

  public getMethodDescription(method: string): string {
    let methodDescription = '';

    switch (method) {
      case 'POST':
        methodDescription = 'Inserir';
        break;
      case 'GET':
        methodDescription = 'Visualizar';
        break;
      case 'PUT':
        methodDescription = 'Atualizar';
        break;
      case 'DELETE':
        methodDescription = 'Excluir';
        break;
    }

    return methodDescription;
  }

  groupByKey(key: string, array: any): RolesGroupedModel[] {
    const roleGroupedModel = [];

    const grouped = array
      .reduce((hash: { [x: string]: any; }, obj: { [x: string]: string | number; }) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {});


    for (const [key, value] of Object.entries(grouped)) {
      roleGroupedModel.push({ description: key, items: grouped[key], checked: false });
    }

    return roleGroupedModel;
  }

  public async remove(): Promise<void> {
    this.confirmationModal.open();
  }

  roleSelected(indexRole: number, indexItem: number, event: any, description: string) {
    this.rolesGrouped[indexRole].items[indexItem].checked = event.target.checked;
    this.rolesGrouped[indexRole].checked = this.allModuleChecked(description);

    this.formGroup.controls['roles'].setValue(Object.assign([], this.rolesGrouped));
    this.formGroup.markAsDirty();
  }

  get form() {
    return this.formGroup.controls;
  }

  afterSave(action: string): void {
    this.toastr.success(`Perfil ${action} com sucesso!`);
    this.router.navigate([this.module.urlList]);
  }

  checkHasRole(id: string): boolean {
    return ((this.formGroup.get('roles')?.value as any[])?.find(x => x._id === id));
  }

  public formGroupToModel(): ProfileModel {

    this.changeTextToUppercase(this.formGroup, ['name', 'description']);

    return new ProfileModel({
      _id: this.formGroup.get('_id')?.value,
      name: this.formGroup.get('name')?.value,
      description: this.formGroup.get('description')?.value,
      roles: this.formGroup.get('roles')?.value?.map((x: any) => x.items).flat().filter((x: any) => x.checked),
    })
  }

  public async getProfiles(): Promise<void> {
    this.profileService.find()
      .then((profiles) => {
        this.data = profiles;
      });
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Toastr } from 'src/app/services/toastr.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { ProfileModel } from 'src/app/shared/models/admin/profile.model';
import { ProfileBaseComponent } from '../profile-base.component';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss']
})
export class ProfileListComponent extends ProfileBaseComponent implements OnInit {

  profiles: ProfileModel[] = [];
  companies: any[] = [];
  selectAllChecked: boolean = false;
  selectedItems: any[] = [];
  search!: string;

  constructor(
    protected override profileService: ProfileService,
    protected override toastr: Toastr,
    protected override router: Router,
    private roleService: RoleService,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService) {
    super(confirmationModal, profileService, toastr, cacheService, router, deviceService);
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  private getData(): void {
    this.cacheService.roles().then((roles) => {
      this.rolesGrouped = this.groupByKey('description', roles);
      this.profileService.find().then((profiles) => {
        this.data = profiles;
        this.auxData = this.data;
      });
    });
  }

  onCancel(): void {
    this.clearForm();
  }

  clearForm(): void {
    this.rolesGrouped.forEach((role, index) => {
      role.checked = false;
      role.items.forEach((item: any, index2: number) => {
        item.checked = false;

        const check1 = document.getElementById(`checkboxPermission${index}${index2}`) || {} as any;

        if (check1) {
          check1.checked = false;
        };
      });

      const check2 = document.getElementById(`checkboxAllPermission${index}`) || {} as any;

      if (check2) {
        check2.checked = false
      };
    });

    this.formGroup.reset();
  }

  async openView(data: ProfileModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
        }
      });
  }

  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: ProfileModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.profileService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural1 = itemsCount > 1 ? 's' : 'l';
      const plural2 = itemsCount > 1 ? 's' : '';

      if (count === itemsCount) {
        this.getProfiles();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Perfi${plural1} excluido${plural2} com sucesso`);
        }
      }
    });
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });
  }

  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  public addNew(): void {
    this.openView(new ProfileModel());
  }

  public selectedItem(item: any) {
    this.selectedItems = [item];
  }

  public filter() {
    const keysFilter = ['name', 'description'];

    if (this.search) {
      if (this.search.length === 0) {
        this.data = this.auxData;
      }

      const ocurrences: any[] = [];

      for (const objeto of this.auxData) {
        for (const chave in objeto) {
          if (keysFilter.includes(chave) && (objeto as any)[chave].toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
            ocurrences.push(objeto);
            break;
          }
        }
      }

      this.data = ocurrences;
    } else {
      this.data = this.auxData;
    }
  }

  public clearSearch(): void {
    this.search = '';
    this.filter();
  }
}

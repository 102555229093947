import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpRequestOptions } from '../shared/models/common/http-request-options.model';
import { environment } from 'src/environments/environment';

export abstract class HttpRequestBaseService<Request, Response> {

  backendUrl!: string;

  constructor(protected http: HttpClient) {
    this.backendUrl = environment.backendUrl;
  }

  protected get({
    path,
    options,
    skip = false,
  }: {
    path: string;
    options?: HttpRequestOptions;
    skip?: boolean;
  }): Promise<Response> {

    if (skip) {
      options = this.setSkip(options);
    }

    return new Promise<Response>((resolve, reject) => {
      this.http.get<Response>(`${this.backendUrl}${path}`, options)
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  protected getArray({
    path,
    options,
    skip = false,
  }: {
    path: string;
    options?: HttpRequestOptions;
    skip?: boolean;
  }): Promise<Array<Response>> {

    if (skip) {
      options = this.setSkip(options);
    }

    return new Promise<Array<Response>>((resolve, reject) => {
      this.http.get<Array<Response>>(`${this.backendUrl
        }/${path}`, options)
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error)
        });
    });
  }

  protected post({
    path,
    request,
    options,
    skip = false,
    func,
  }: {
    path: string;
    request: Request;
    options?: HttpRequestOptions;
    skip?: boolean;
    func?: Function
  }): Promise<Response> {

    if (skip) {
      options = this.setSkip(options);
    }

    return new Promise<Response>((resolve, reject) => {
      this.http.post<Response>(`${this.backendUrl}${path}`, request, options)
        .subscribe({
          next: (response) => {
            if (func) {
              func();
            }
            resolve(response);
          },
          error: (error) => reject(error)
        });
    });
  }

  protected put({
    path,
    request,
    options,
    skip = false,
    func
  }: {
    path: string;
    request?: Request;
    options?: HttpRequestOptions;
    skip?: boolean;
    func?: Function;
  }): Promise<Response> {

    if (skip) {
      options = this.setSkip(options);
    }

    return new Promise<Response>((resolve, reject) => {
      this.http.put<Response>(`${this.backendUrl}${path}`, request, options)
        .subscribe({
          next: (response) => {
            if (func) {
              func();
            }
            resolve(response);
          },
          error: (error) => reject(error)
        });
    });
  }

  protected delete({
    path,
    options,
    skip = false,
    func,
  }: {
    path: string;
    options?: HttpRequestOptions;
    skip?: boolean;
    func?: Function;
  }): Promise<Response> {
    if (skip) {
      options = this.setSkip(options);
    }

    return new Promise<Response>((resolve, reject) => {
      this.http.delete<Response>(`${this.backendUrl}${path}`, options)
        .subscribe({
          next: (response) => {
            if (func) {
              func();
            }
            resolve(response);
          },
          error: (error) => reject(error)
        });
    });
  }

  private setSkip(
    options: HttpRequestOptions | undefined,
  ): HttpRequestOptions {
    if (!options) {
      options = new HttpRequestOptions({
        headers: new HttpHeaders(),
      });
    }

    options.headers = options?.headers?.set('skip', 'true');

    return options;
  }
}

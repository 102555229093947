import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuotationListComponent } from './quotation/quotation-list.component';
import { QuotationAllListComponent } from './quotation-all-list/quotation-all-list.component';

const routes: Routes = [
  {
    path: '',
    component: QuotationListComponent
  },
  {
    path: 'list',
    component: QuotationAllListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class quotationRoutingModule { }

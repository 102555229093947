<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Blank Page</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Blank Page</li>
        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<section class="content">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Title</h3>
  
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
           <tr>
              <th style="width: 150px">Cod. da Lista</th>
              <th>Data Criação</th>
              <th>Data Fechamento</th>
              <th>Data Compra</th>
              <th style="width: 50px">Status</th>
           </tr>
        </thead>
        <tbody>
           <tr>
              <td>1.</td>
              <td>
                 01/12/2021
              </td>
              <td>
                01/12/2021
             </td>
             <td>
              01/12/2021
              </td>
              <td><span class="badge bg-success">Aberto</span></td>
           </tr>
           <tr>
              <td>2.</td>
              <td>
                01/12/2021
              </td>
              <td>
                01/12/2021
             </td>
             <td>
              01/12/2021
           </td>
              <td><span class="badge bg-warning">70%</span></td>
           </tr>
           <tr>
              <td>3.</td>
              <td>
                01/12/2021
              </td>
              <td>
                01/12/2021
             </td>
             <td>
              01/12/2021
           </td>
              <td><span class="badge bg-primary">30%</span></td>
           </tr>
           <tr>
              <td>4.</td>
              <td>
                01/12/2021
              </td>
              <td>
                01/12/2021
             </td>
             <td>
              01/12/2021
           </td>
              <td><span class="badge bg-success">90%</span></td>
           </tr>
        </tbody>
     </table>
    </div>
    <!-- <div class="card-footer">
      Footer
    </div> -->
  </div>
</section>

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{module.parentName}}</a></li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6">
                    <h2 class="card-title card-title-center">{{module.name}}</h2>
                </div>
                <div class="col-6">
                    <button
                        [style.visibility]="hasPermission(module.permission.DELETE) && form['_id'].value ? 'visible' : 'hidden'"
                        type="button" class="btn btn-danger btn-sm btn-card-action" title="Excluir" (click)="remove()">
                        <i class="fas fa-trash-alt fa-sm"></i> excluir
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form #formGroup2 [formGroup]="formGroup">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="name">Nome</label>
                                    <input type="text" #name class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['name'].invalid && (form['name'].dirty || form['name'].touched) }"
                                        formControlName="name" />
                                    <span *ngIf="form['name'].invalid && (form['name'].dirty || form['name'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="address">Endereço</label>
                                    <input type="text" #address class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['address'].invalid && (form['address'].dirty || form['address'].touched) }"
                                        formControlName="address" />
                                    <span
                                        *ngIf="form['address'].invalid && (form['address'].dirty || form['address'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label" for="email">E-mail</label>
                                    <input type="text" #email class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['email'].invalid && (form['email'].dirty || form['email'].touched) }"
                                        formControlName="email" />
                                    <span
                                        *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-12">
                                <fieldset class="fieldset" style="margin-bottom: 1.3rem;"
                                    [ngClass]="{'fieldset-error': form['email'].invalid && (form['email'].dirty || form['email'].touched) }">
                                    <legend>E-mails</legend>

                                    <div class="row" *ngFor="let item of formGroup.get('email')?.value ; let i = index">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-8 col-sm-12 col-lg-8">
                                                            <label class="col-form-label"
                                                                for="email{{i}}">E-mail</label>
                                                            <div class="form-group">
                                                                <input [value]="formGroup.get('email')?.value[i]"
                                                                    (change)="setEmail(i, $event)" type="text"
                                                                    [ngClass]="{'is-invalid': form['email'].invalid && (form['email'].dirty || form['email'].touched) }"
                                                                    class="form-control" id="email{{i}}" />
                                                            </div>
                                                        </div>

                                                        <div class="col-1"
                                                            *ngIf="formGroup.get('email')?.value.length > 1">
                                                            <label class="col-form-label">&nbsp;</label>
                                                            <div class="form-group">
                                                                <button class="form-control btn btn-default"
                                                                    (click)="removeEmail(i)"
                                                                    style="height: calc(2.25rem + 2px); width: 50px;">
                                                                    <i class="far fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8 col-sm-12 col-lg-8">
                                            <div class="form-group">
                                                <div class="btn btn-default" (click)="addEmail()" style="float: right;">
                                                    <i class="fas fa-plus"></i> Adicionar
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        *ngIf="form['email'].invalid && (form['email'].dirty || form['email'].touched)"
                                        class="error invalid-feedback">Informe pelo menos 1 e-mail de contato</span>
                                </fieldset>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12">
                                <fieldset class="fieldset" style="margin-bottom: 1.3rem;"
                                    [ngClass]="{'fieldset-error': form['contact'].invalid && (form['contact'].dirty || form['contact'].touched) }">
                                    <legend>Contato</legend>

                                    <div class="row"
                                        *ngFor="let item of formGroup.get('contact')?.value ; let i = index">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-4 col-sm-12 col-lg-4">
                                                            <label class="col-form-label" for="name{{i}}">Nome</label>
                                                            <div class="form-group">
                                                                <input [value]="formGroup.get('contact')?.value[i].name"
                                                                    (change)="setContato(i, 'name', $event)" type="text"
                                                                    [ngClass]="{'is-invalid': form['contact'].invalid && (form['contact'].dirty || form['contact'].touched) }"
                                                                    class="form-control" inputmode="text"
                                                                    id="name{{i}}" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-sm-12 col-lg-4">
                                                            <label class="col-form-label"
                                                                for="phone_number{{i}}">Telefone</label>
                                                            <div class="form-group">
                                                                <input
                                                                    [value]="formGroup.get('contact')?.value[i].phone_number"
                                                                    (change)="setContato(i, 'phone_number', $event)"
                                                                    type="text" (change)="handlePhone($event)"
                                                                    (keyup)="handlePhone($event)"
                                                                    [ngClass]="{'is-invalid': form['contact'].invalid && (form['contact'].dirty || form['contact'].touched) }"
                                                                    class="form-control" inputmode="text"
                                                                    id="phone_number{{i}}" />
                                                            </div>
                                                        </div>

                                                        <div class="col-1"
                                                            *ngIf="formGroup.get('contact')?.value.length > 1">
                                                            <label class="col-form-label">&nbsp;</label>
                                                            <div class="form-group">
                                                                <button class="form-control btn btn-default"
                                                                    (click)="removeContact(i)"
                                                                    style="height: calc(2.25rem + 2px); width: 50px;">
                                                                    <i class="far fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8 col-sm-12 col-lg-8">
                                            <div class="form-group">
                                                <div class="btn btn-default" (click)="addContact()"
                                                    style="float: right;"><i class="fas fa-plus"></i> Adicionar</div>
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        *ngIf="form['contact'].invalid && (form['contact'].dirty || form['contact'].touched)"
                                        class="error invalid-feedback">Informe pelo menos 1 contato (nome e
                                        telefone)</span>
                                </fieldset>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Categoria</label>
                                    <select #selectcategory formControlName="category"
                                        [ngClass]="{'is-invalid': form['category'].invalid && (form['category'].dirty || form['category'].touched) }"
                                        (change)="categorySelected($event)" class="form-control select2bs4 select2"
                                        style="width: 100%;">
                                        <option *ngFor="let item of categories; let i = index" [value]="item?._id">
                                            {{item?.name}}
                                        </option>
                                    </select>
                                    <span
                                        *ngIf="form['category'].invalid && (form['category'].dirty || form['category'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label" for="minimumValue">Valor mín. Compra</label>
                                    <input type="text" class="form-control uppercase" mask="separator.2"
                                        [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ "
                                        [dropSpecialCharacters]="true" [leadZero]="true"
                                        [ngClass]="{'is-invalid': form['minimumValue'].invalid && (form['minimumValue'].dirty || form['minimumValue'].touched) }"
                                        formControlName="minimumValue" />

                                    <span
                                        *ngIf="form['minimumValue'].invalid && (form['minimumValue'].dirty || form['minimumValue'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label class="col-form-label" for="minimumAmount">Qtde. mín. Compra</label>
                                    <input type="text" #minimumAmount class="form-control uppercase"
                                        [ngClass]="{'is-invalid': form['minimumAmount'].invalid && (form['minimumAmount'].dirty || form['minimumAmount'].touched) }"
                                        formControlName="minimumAmount" />
                                    <span
                                        *ngIf="form['minimumAmount'].invalid && (form['minimumAmount'].dirty || form['minimumAmount'].touched)"
                                        class="error invalid-feedback">Campo obrigátorio</span>
                                </div>
                            </div>
                        </div>


                        <div class="row action-save-back" *ngIf="isMobile()">
                            <div class="col-6">
                                <button type="button" class="btn btn-default text- btn-sm pull-left"
                                    [routerLink]="[module.urlList]"><i class="far fa-times-circle"></i>
                                    cancelar</button>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    *ngIf="hasPermission(module.permission.UPDATE) && form['_id'].value || hasPermission(module.permission.ADD) && !form['_id'].value"
                                    class="btn btn-primary btn-sm btn-card-action">
                                    <i class="fa fa-save"></i> salvar
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="!isMobile()">
                            <div class="col-6 align-self-end ml-auto">
                                <button type="button" (click)="onConfirm()" [disabled]="formGroup.status != 'VALID'"
                                    class="btn btn-primary btn-sm pull-right"><i class="fa fa-save"></i> salvar</button>

                                <button type="button" class="btn btn-default btn-sm pull-right"
                                    style="margin-right: 10px;" [routerLink]="[module.urlList]"><i
                                        class="far fa-times-circle"></i> cancelar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<confirmation-modal (onConfirm)="onConfirmRemove()" (onCancel)="onCancelRemove()"
    messageText="Deseja realmente excluir?"> </confirmation-modal>
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Toastr {

  constructor(private toastSevice: ToastrService) {

  }
  public defaultOptions = {
    timeOut: 4000,
    preventDuplicates: true,
    closeButton: true,
  }

  public success(msg: string, title?: string, options?: any) {
    this.toastSevice.success(msg, title, Object.assign(this.defaultOptions, options));
  }

  public error(msg: string, title?: string, options?: any) {
    this.defaultOptions.timeOut = 0;
    this.toastSevice.error(msg, title, Object.assign(this.defaultOptions, options));
  }

  public info(msg: string, title?: string, options?: any) {
    this.toastSevice.info(msg, title, Object.assign(this.defaultOptions, options));
  }

  public warning(msg: string, title?: string, options?: any) {
    this.defaultOptions.timeOut = 0;
    this.toastSevice.warning(msg, title, Object.assign(this.defaultOptions, options));
  }
}
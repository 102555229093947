import { Injectable } from "@angular/core";
import { CompanyModel } from "../shared/models/admin/company.model";
import { CompanyService } from "./company.service";
import { ProfileModel } from "../shared/models/admin/profile.model";
import { ProfileService } from "./profile.service";
import { RoleModel } from "../shared/models/admin/role.model";
import { RoleService } from "./role.service";
import { SupplierService } from "./supplier.service";
import { PurchaseCategoryModel } from "../shared/models/purchase/purchase-category.model";
import { PurchaseCategoryService } from "./purchase-category.service";
import { PurchaseItemsModel } from "../shared/models/purchase/purchase-items.model";
import { PurchaseItemsService } from "./purchase-items.service";
import { QuantityStockItemsService } from "./quantity-stock-items.service";
import { QuantityStockItemsModel } from "../shared/models/purchase/quantity-stock-items.model";
import { QuotationModel } from "../shared/models/purchase/quotation.model";
import { QuotationService } from "./quotation.service";

@Injectable({ providedIn: 'root' })
export class CacheService {

    private _companies: CompanyModel[] = [];
    private _profiles: ProfileModel[] = [];
    private _roles: RoleModel[] = [];
    private _supplierCategories: PurchaseCategoryModel[] = [];
    private _purchaseItems: PurchaseItemsModel[] = [];
    private _quantityStockItemsModel: QuantityStockItemsModel[] = [];
    private _quotation: QuotationModel[] = [];

    public purchaseCategories(update: boolean = false): Promise<PurchaseCategoryModel[]> {
        return new Promise((resolve, reject) => {
            if (update || !this._supplierCategories || !this._supplierCategories.length) {
                this.purchaseCategoryService.find().then(supplierCategories => {
                    this._supplierCategories = supplierCategories;
                    resolve(supplierCategories);
                }, () => reject([]));
            } else {
                resolve(this._supplierCategories);
            }
        });
    }

    public companies(update: boolean = false): Promise<CompanyModel[]> {
        return new Promise((resolve, reject) => {
            if (update || !this._companies || !this._companies.length) {
                this.companyService.find().then(companies => {
                    this._companies = companies;
                    resolve(companies);
                }, () => reject([]));
            } else {
                resolve(this._companies);
            }
        });
    }

    public profiles(update: boolean = false): Promise<ProfileModel[]> {
        return new Promise((resolve, reject) => {
            if (update || !this._profiles || !this._profiles.length) {
                this.profileService.getDomain().then(profiles => {
                    this._profiles = profiles;
                    resolve(profiles);
                }, () => reject([]));
            } else {
                resolve(this._profiles);
            }
        });
    }

    public quotations(update: boolean = false): Promise<QuotationModel[]> {
        return new Promise((resolve, reject) => {
            if (update || !this._quotation || !this._quotation.length) {
                this.quotationService.find().then(quotation => {
                    this._quotation = quotation;
                    resolve(quotation);
                }, () => reject([]));
            } else {
                resolve(this._quotation);
            }
        });
    }

    public roles(update: boolean = false): Promise<RoleModel[]> {
        return new Promise((resolve, reject) => {
            if (update || !this._roles || !this._roles.length) {
                this.roleService.find().then(roles => {
                    this._roles = roles;
                    resolve(roles);
                }, () => reject([]));
            } else {
                resolve(this._roles);
            }
        });
    }

    public purchaseItems(update: boolean = false): Promise<PurchaseItemsModel[]> {
        return new Promise((resolve, reject) => {
            if (update || (!this._purchaseItems || !this._purchaseItems.length)) {
                this.purchaseItemsService.find().then(purchaseItems => {
                    this._purchaseItems = purchaseItems;
                    resolve(purchaseItems);
                }, () => reject([]));
            } else {
                resolve(this._purchaseItems);
            }
        });
    }

    public quantityStock(update: boolean = false): Promise<QuantityStockItemsModel[]> {
        return new Promise((resolve, reject) => {
            if (update || (!this._quantityStockItemsModel || !this._quantityStockItemsModel.length)) {
                this.quantityStockItemsService.find().then(quantityStockItemsModel => {
                    this._quantityStockItemsModel = quantityStockItemsModel;
                    resolve(quantityStockItemsModel);
                }, () => reject([]));
            } else {
                resolve(this._quantityStockItemsModel);
            }
        });
    }

    constructor(
        private companyService: CompanyService,
        private profileService: ProfileService,
        private roleService: RoleService,
        private supplierService: SupplierService,
        private purchaseItemsService: PurchaseItemsService,
        private quotationService: QuotationService,
        private purchaseCategoryService: PurchaseCategoryService,
        protected quantityStockItemsService: QuantityStockItemsService,
    ) {

        // if (!this._companies || !this._companies.length) {
        //     this.companyService.get().then(companies => {
        //         this._companies = companies;
        //         this.companies.next(companies);
        //     });
        // } else {
        //     this.companies.next(this._companies);
        // }

        // if (!this._profiles || !this._profiles.length) {
        //     this.profileService.get().then(profiles => {
        //         this._profiles = profiles;
        //         this.profiles.next(profiles);
        //     });
        // } else {
        //     this.profiles.next(this._profiles);
        // }

        // if (!this._roles || !this._roles.length) {
        //     this.roleService.get().then(roles => {
        //         this._roles = roles;
        //         this.roles.next(roles);
        //     });
        // } else {
        //     this.roles.next(this._roles);
        // }

        // if (!this._suppliers || !this._suppliers.length) {
        //     this.supplierService.getSupplier().then(suppliers => {
        //         this._suppliers = suppliers;
        //         this.suppliers.next(suppliers);
        //     });
        // } else {
        //     this.suppliers.next(this._suppliers);
        // }

        // if (!this._supplierCategories || !this._supplierCategories.length) {
        //     this.supplierService.getSupplier().then(supplierCategories => {
        //         this._supplierCategories = supplierCategories;
        //         this.supplierCategories.next(supplierCategories);
        //     });
        // } else {
        //     this.supplierCategories.next(this._supplierCategories);
        // }
    }
}
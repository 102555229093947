import { Component } from '@angular/core';
import { Toastr } from 'src/app/services/toastr.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { Router } from '@angular/router';
import { PurchaseCategoryItemsBaseComponent } from '../purchase-category-items-base.component';
import { PurchaseItemsService } from 'src/app/services/purchase-items.service';
import { PurchaseCategoryItemsService } from 'src/app/services/purchase-category-items.service';
import { PurchaseCategoryItemsModel } from 'src/app/shared/models/purchase/purchase-category-items.model';
import { PurchaseCategoryService } from 'src/app/services/purchase-category.service';

@Component({
  selector: 'app-purchase-category-items',
  templateUrl: './purchase-category-items-list.component.html',
  styleUrls: ['./purchase-category-items-list.component.scss']
})
export class PurchaseCategoryItemsListComponent extends PurchaseCategoryItemsBaseComponent {

  selectAllChecked: boolean = false;
  selectedRowIndex!: number;
  selectedItems: any[] = [];
  reopenPurchaseCategoryItemsModal = false;

  constructor(
    protected override purchaseCategoryItemsService: PurchaseCategoryItemsService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService,
    protected override router: Router,
    protected override purchaseCategoryService: PurchaseCategoryService,
    protected override purchaseItemsService: PurchaseItemsService,
  ) {
    super(
      purchaseCategoryItemsService,
      toastr,
      modalService,
      cacheService,
      confirmationModal,
      router,
      deviceService,
      purchaseCategoryService,
      purchaseItemsService,
    );
  }

  ngOnInit(): void {
    this.checkRoute(this.module.permission.GET);
    this.getData();
  }

  selectItem($event: any, index: number): void {
    this.data[index].checked = $event.target.checked;
    this.selectedItems = this.data.filter(x => x.checked);
    this.selectAllChecked = this.data.filter(x => x.checked).length === this.data.length;
  }

  public onConfirmRemove(): void {
    this.confirmationModal.close();

    const itemsCount = this.selectedItems.length;
    let count = 0;

    let oneItem!: PurchaseCategoryItemsModel;
    let itemsErrors: any[] = [];

    this.selectedItems.forEach(async (item) => {
      oneItem = item;

      try {
        await this.purchaseCategoryItemsService.remove(item._id);
      } catch (error: any) {
        itemsErrors.push(error.error.errors);
      }

      count++;

      const plural1 = itemsCount > 1 ? 's' : '';
      const plural2 = itemsCount > 1 ? 'ns' : 'm';

      if (count === itemsCount) {
        this.getData();
        this.selectedItems = [];

        if (itemsErrors && itemsErrors.length) {
          itemsErrors.map((error) => this.toastr.error(error));
        } else {
          this.toastr.success(`Categoria${plural1} x Ite${plural2} excluido${plural1} com sucesso`);
        }
      }
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  selectAll($event: any): void {
    this.data.forEach((d) => {
      d.checked = $event.target.checked;
    });

    this.selectedItems = this.data.filter(x => x.checked);
  }

  public addNew(): void {
    this.openView(new PurchaseCategoryItemsModel());
  }

  public selectedItem(item: PurchaseCategoryItemsModel) {
    this.selectedItems = [item];
  }

  async openView(data: PurchaseCategoryItemsModel) {
    this.router.navigate([this.module.urlView],
      {
        state: {
          data: data,
          categories: this.categories,
          items: this.items,
          itemsLeft: this.itemsLeft,
          categoriesLeft: this.categoriesLeft
        }
      });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageHelper } from 'src/app/shared/utils/local-storage';
import { Toastr } from 'src/app/services/toastr.service';
import { GoogleSigninButtonModule, SocialLoginModule } from '@abacritt/angularx-social-login';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // GoogleSigninButtonModule,
    // SocialLoginModule,
    RouterModule
  ],
  providers: [AuthService, LocalStorageHelper, Toastr]
})
export class LoginModule { }

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { QuotationModel } from "../shared/models/purchase/quotation.model";
import { HttpRequestBaseService } from "./http-request-base.service";

@Injectable()
export class QuotationService extends HttpRequestBaseService<any, any> {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  path: string = '/v1/quotation';

  public async find(): Promise<QuotationModel[]> {
    return this.get({
      path: this.path,
    });
  }

  public async add(quotation: QuotationModel): Promise<any> {
    return this.post({
      path: this.path,
      request: quotation
    });
  }

  public async update(_id: string, item: any): Promise<any> {
    return this.put({
      path: `${this.path}/${_id}`,
      request: item,
      skip: true
    });
  }

  public async finalize(_id: string): Promise<any> {
    return this.put({
      path: `${this.path}/finalize/${_id}`,
      request: {}
    });
  }

  public async remove(_id: string): Promise<any> {
    return this.delete({
      path: `${this.path}/${_id}`,
    });
  }

  public async last(_id: string): Promise<QuotationModel> {
    return this.get({
      path: `${this.path}/last/${_id}`,
    });
  }

  public async findOne(_id: string): Promise<QuotationModel> {
    return this.get({
      path: `${this.path}/${_id}`,
    });
  }

}
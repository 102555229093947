import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Toastr } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  showSuccess = false;
  showError = false;
  formGroup!: FormGroup;
  successText = 'Aguarde você será direcionado para <b>criar uma senha</b> de acesso a sua conta...';
  successTitle = 'E-mail confirmado!';

  errorText = 'Informe seu endereço de e-mail abaixo que enviaremos um novo link.';
  errorTitle = 'Oops! Esse link não é mais válido.';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toast: Toastr) {

  }
  ngOnInit(): void {
    this.createForm();

    this.route.paramMap.subscribe(paramMap => {
      this.authService.verifyEmail(paramMap.get('id'))
        .then((response) => {
          if (response && response.change_password_id) {
            this.showSuccess = true;
            setTimeout(() => {
              this.router.navigate(['change-password', response.change_password_id], { queryParams: { type: 'new' } });
            }, 6000);
          } else {
            this.successText = 'Aguarde você será direcionado para o login...';
            this.showSuccess = true;
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 6000);
          }
        }, () => {
          this.showError = true;
        });
    })
  }

  createForm(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  get form() {
    return this.formGroup.controls;
  }

  send(): void {
    this.authService.generateVerifyEmail(this.formGroup.get('email')?.value)
      .then(() => {
        this.formGroup.reset();
        this.showSuccess = true;
        this.showError = false;
        this.successTitle = 'E-mail enviado com sucesso!';
        this.successText = 'Dentro de alguns instantes você receberá um e-mail de verificação'
      }, () => {
        this.toast.error('Não foi possivel enviar um novo e-mail de verificação de conta');
      });
  }


  isValid(inputname: string) {

    return this.formGroup.controls[inputname].invalid &&
      (this.formGroup.controls[inputname].dirty || this.formGroup.controls[inputname].touched)
  }
}

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h3>{{module.pluralName}}</h3>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">{{module.parentName}}</li>
                    <li class="breadcrumb-item active">{{module.pluralName}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<section class="content">

    <div class="row">
        <div class="col-12 col-md-12">
            <div class="card card-primary card-outline card-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item" *ngFor="let dataItem of dinamicTable; let i = index">
                            <a class="nav-link" [ngClass]="{ 'active': i == 0 }" id="custom-tabs-{{i}}-tab"
                                data-toggle="pill" href="#custom-tabs-{{i}}" role="tab"
                                attr.aria-controls="custom-tabs-{{i}}"
                                aria-selected="true">{{dataItem.category_name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade" [ngClass]="{ 'active show': i == 0 }"
                            *ngFor="let dataItem of dinamicTable; let i = index" id="custom-tabs-{{i}}" role="tabpanel"
                            attr.aria-labelledby="custom-tabs-{{i}}-tab">



                            <div class="row">
                                <div class="col-12">

                                    <div class="card-body table-responsive p-0">
                                        <table stickyTable class="table table-hover table-head-fixed text-nowrap"
                                            id="quotations{{i}}">
                                            <thead>
                                                <tr>
                                                    <th style="z-index: 9999;" sticky>Item</th>
                                                    <th *ngFor="let column of getColumnsByIndex(i)">
                                                        {{column.name}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let items of dataItem.items; let j = index">
                                                    <th class="product-name" sticky>{{items.product}}</th>
                                                    <td appFormatCurrency [value]="getValue(items, i, indexColumn)"
                                                        [ngClass]="{'highlighted': getValue(items, i, indexColumn) === items.minorPrice && items.qtdePrices > 1 && !isMinorPriceDuplicate(items.prices, items.minorPrice) }"
                                                        *ngFor="let column of getColumnsByIndex(i); let indexColumn = index">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>
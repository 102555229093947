import { Component } from '@angular/core';

@Component({
  selector: 'app-not-app-installed',
  templateUrl: './not-app-installed.component.html',
  styleUrls: ['./not-app-installed.component.scss']
})
export class NotAppInstalledComponent {

}

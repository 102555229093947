import { Component, OnInit } from '@angular/core';
import { SupplierBaseComponent } from '../supplier-base.component';
import { SupplierService } from 'src/app/services/supplier.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Toastr } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { SupplierModel } from 'src/app/shared/models/purchase/supplier.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormArray } from '@angular/forms';
import { ContactModel } from 'src/app/shared/models/purchase/contact.model';
import { UtilsService } from 'src/app/services/utils.service';
import { PurchaseCategoryModel } from 'src/app/shared/models/purchase/purchase-category.model';

@Component({
  selector: 'app-supplier-view',
  templateUrl: './supplier-view.component.html',
  styleUrls: ['./supplier-view.component.scss']
})
export class SupplierViewComponent extends SupplierBaseComponent implements OnInit {

  state: any;
  selectedItems: any[] = [];

  constructor(
    protected override supplierService: SupplierService,
    protected override modalService: ModalService,
    protected override toastr: Toastr,
    protected override router: Router,
    protected override cacheService: CacheService,
    protected override deviceService: DeviceDetectorService,
    protected override confirmationModal: ConfirmationModalService
  ) {
    super(supplierService, toastr, modalService, cacheService, confirmationModal, router, deviceService);

    this.state = this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {

    let data = (this.state?.['data'] as any);
    let categories = (this.state?.['categories'] as PurchaseCategoryModel[]);

    if (!data) {
      this.router.navigate([this.module.urlList]);
    }

    this.checkRoute(data?._id ? this.module.permission.UPDATE : this.module.permission.ADD);

    this.categories = categories;

    this.createForm(data || new SupplierModel());
  }

  public onRemove(): void {
    this.selectedItems = [this.itemOpenedToEdit];
    this.confirmationModal.open();
  }

  public async onConfirmRemove(): Promise<void> {
    this.confirmationModal.close();
    this.supplierService.remove(this.itemOpenedToEdit._id).then(() => {
      this.toastr.success(`${this.module.name} excluído com sucesso`);
      this.router.navigate([this.module.urlList]);
    }, (errorResponse) => {
      this.toastr.error(errorResponse.error.errors);
    });
  }

  public onCancelRemove(): void {
    this.confirmationModal.close();
  }

  onConfirm(): void {
    if (this.formGroup.valid) {

      const model = this.formGroupToModel();
      const method = !this.formGroup.get('_id')?.value ? 'add' : 'update';
      const action = method === 'add' ? 'criado' : 'atualizado';

      this.supplierService[method](model)
        .then(() => {
          this.afterSave(action);
        }, (responseError) => {
          this.toastr.error(responseError.error.errors);
        });
    }
  }

  categorySelected($event: any): void {
    this.form['category'].setValue($event.target.value || null);
    this.form['category'].markAsDirty();
  }

  addContact(): void {
    let contact: any[] = this.formGroup.get('contact')?.value as any;
    contact = contact.concat(new ContactModel({ name: '', phone_number: '' }));
    this.formGroup.controls['contact'].setValue(contact);
  }

  addEmail(): void {
    let emails: any[] = this.formGroup.get('email')?.value as any;
    emails = emails.concat('');
    this.formGroup.controls['email'].setValue(emails);
  }

  get phone_number() {
    return this.formGroup.controls["phone_number"] as FormArray;
  }

  setContato(index: number, key: string, $event: any) {
    const contact = this.formGroup.get('contact')?.value;

    contact[index][key] = $event.target.value;

    if (contact[index][key] && contact[index][key].name) {
      contact[index][key].name = contact[index][key].name.toUpperCase();
    }

    this.formGroup.controls['contact'].setValue(contact);
    this.formGroup.controls['contact'].markAsDirty();
    this.formGroup.controls['contact'].markAsTouched();
  }

  setEmail(index: number, $event: any) {
    const emails = this.formGroup.get('email')?.value;

    emails[index] = $event.target.value;

    this.formGroup.controls['email'].setValue(emails);
    this.formGroup.controls['email'].markAsDirty();
    this.formGroup.controls['email'].markAsTouched();
  }

  removeContact(index: any): void {
    let contacts = this.formGroup.get('contact')?.value;

    delete contacts[index];

    contacts = UtilsService.reIndex(contacts);

    this.formGroup.controls['contact'].setValue(contacts);
  }

  removeEmail(index: number): void {
    let emails = this.formGroup.get('email')?.value;
    delete emails[index];
    emails = UtilsService.reIndex(emails);
    this.formGroup.controls['email'].setValue(emails);
  }
}

import { HttpHeaders, HttpParams } from '@angular/common/http';
export class HttpRequestOptions {
  constructor({ headers, params }: { headers?: HttpHeaders; params?: HttpParams }) {
    this.headers = headers;
    this.params = params;
  }

  headers?: HttpHeaders;
  observe!: 'body';
  params?: HttpParams;
  responseType!: 'json';
}
